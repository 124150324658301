import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import RequestItemDetails from "./RequestItemDetails";
import Primary from "components/Typography/Primary";
import {
  REQUEST_STATUS,
  REQUEST_STATUS_LABEL,
} from "../../../../src/constants";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const dateLeft = (date) => {
  const startDate = moment(date);
  const today = moment();
  let diffSeconds = startDate.diff(today, "seconds"); // 86400000
  if (diffSeconds < 0) return "";
  const days = Math.floor(diffSeconds / (3600 * 24));
  diffSeconds -= days * 3600 * 24;
  const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
  if (days > 0 && hours > 0) return `${days} day(s) and ${hours} hour(s) left`;
  if (days > 0 && hours === 0) return `${days} day(s) left`;
  if (days === 0 && hours > 0) return `${hours} hour(s) left`;
};

const RequestItemDialog = (props) => {
  const classes = useStyles(styles);
  return (
    <Dialog
      fullScreen
      disableEnforceFocus={true}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        style={{ backgroundColor: "#eee" }}
        className={classes.modalHeader}
      >
        <div style={{ position: "absolute", right: 15, top: 10 }}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={props.onClose}
          >
            <Close className={classes.modalClose} />
          </Button>
          <br />
          <Typography
            style={{ float: "right", marginRight: 15 }}
            variant="h4"
            color="primary"
          >
            #{props.request.identifier}
          </Typography>
        </div>
        <Primary>
          <h1 style={{ fontWeight: 400, textAlign: "center", marginTop: 0 }}>
            {props.request.type}[{REQUEST_STATUS_LABEL[props.request.status]}]
          </h1>
          {(props.request.status === REQUEST_STATUS.PENDING ||
            props.request.status === REQUEST_STATUS.ACCEPTED) && (
            <Typography
              style={{ fontSize: 14, textAlign: "center", marginTop: -20 }}
            >
              {dateLeft(props.request.start_date)}
            </Typography>
          )}
        </Primary>
      </DialogTitle>
      <DialogContent
        style={{ backgroundColor: "#eee" }}
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <RequestItemDetails
          tracking={props.tracking}
          onRejectRequest={props.onRejectRequest}
          onConfirmRequest={props.onConfirmRequest}
          onConfirmOldRequest={props.onConfirmOldRequest}
          onArchiveRequest={props.onArchiveRequest}
          onCheckRequest={props.onCheckRequest}
          request={props.request}
          onFinish={props.onFinish}
          onSubcontractorRequestChange={props.onSubcontractorRequestChange}
        />
      </DialogContent>
    </Dialog>
  );
};
export default RequestItemDialog;

RequestItemDialog.prototype = {
  isModalVisible: PropTypes.bool,
};

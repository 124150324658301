import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import moment from "moment";
import {
  FormControl,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Check from "@material-ui/icons/Check";
import Moment from "moment";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import Primary from "components/Typography/Primary";
import Typography from "@material-ui/core/Typography";
import CustomPlaceInput from "components/CustomPlaceInput/CustomPlaceInput";
import { REQUEST_TYPE, primaryColor } from "../../../../../src/constants";
import ImportExportIcon from "@material-ui/icons/ImportExport";
const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
};

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    const request = props.request;
    this.state = {
      selectedRequest: request ? request.type : REQUEST_TYPE.MONITORING,
      startDate: request ? Moment(request.start_date) : null,
      startDateState: "",
      toDate: request ? Moment(request.end_date) : null,
      toDateState: "",
      pup: request ? request.pup : "",
      pupState: "",
      dop: request ? request.dop : "",
      dopState: "",
      guardsPerEscort: request ? request.escort_number : 1,
    };
  }
  sendState() {
    return this.state;
  }
  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = (name) => (event) => {
    this.setState({ selectedRequest: name });
    this.clearState();
  };

  clearState = () => {
    this.setState({
      toDate: null,
      toDateState: "",
      pup: "",
      pupState: "",
      dop: "",
      dopState: "",
      guardsPerEscort: 1,
      isTakeOver: false,
    });
  };

  isValidDate = (date, key) => {
    if (date instanceof Moment) {
      // if (key === "startDate" && date.isBefore(moment())) {
      //   return false;
      // }
      return true;
    }
    return false;
  };

  isValidated() {
    //return true;
    const {
      startDate,
      toDate,
      pup,
      dop,
      guardsPerEscort,
      takeOverDop,
      takeOverDopState,
      takeOverPup,
      takeOverPupState,
      takeOverStartDate,
      takeOverStartDateState,
    } = this.state;
    const { selectedRequest, isTakeOver } = this.state;
    let isValid = true;
    if (!this.isValidDate(startDate, "startDate")) {
      this.setState({ startDateState: "error" });
      isValid = false;
    }
    switch (selectedRequest) {
      case REQUEST_TYPE.MONITORING: {
        if (!pup) {
          // if (!pup || !dop) {
          if (!pup) this.setState({ pupState: "error" });
          // if (!dop) this.setState({ dopState: "error" });
          isValid = false;
        }
        break;
      }
      case REQUEST_TYPE.ESCORT: {
        if (!pup || !dop || !guardsPerEscort) {
          if (!pup) this.setState({ pupState: "error" });
          if (!dop) this.setState({ dopState: "error" });
          if (!guardsPerEscort)
            this.setState({ guardsPerEscortState: "error" });

          isValid = false;
        }
        if (isTakeOver) {
          if (!takeOverDop) {
            this.setState({ takeOverDopState: "error" });
            isValid = false;
          }
          if (!takeOverPup) {
            this.setState({ takeOverPupState: "error" });
            isValid = false;
          }
          if (!this.isValidDate(takeOverStartDate, "startDate")) {
            this.setState({ takeOverStartDateState: "error" });
            isValid = false;
          }
          if (!takeOverStartDate || takeOverStartDate.isBefore(startDate)) {
            this.setState({ takeOverStartDateState: "error" });
            isValid = false;
          }
        }
        break;
      }
      case REQUEST_TYPE.STATIC: {
        if (!this.isValidDate(toDate)) {
          this.setState({ toDateState: "error" });
          isValid = false;
        }
        if (!pup) {
          this.setState({ pupState: "error" });
          isValid = false;
        }
        break;
      }
      case REQUEST_TYPE.INTERVENTION: {
        if (!pup) {
          this.setState({ pupState: "error" });
          isValid = false;
        }
        break;
      }
      default:
        break;
    }
    return isValid;
  }

  onDataChanged = (key, value) => {
    if (key === "startDate" || key === "toDate") {
      if (this.isValidDate(value, key)) {
        this.setState({ [key + "State"]: "success" });
      } else {
        this.setState({ [key + "State"]: "error" });
      }
    } else {
      if (value) {
        this.setState({ [key + "State"]: "success" });
      } else {
        this.setState({ [key + "State"]: "error" });
      }
    }

    this.setState({ [key]: value });
  };

  validation = (currentDate) => {
    return currentDate.isSameOrAfter(moment().add("days", -1));
  };
  validationToDate = (currentDate) => {
    return currentDate.isSameOrAfter(this.state.startDate);
  };

  render() {
    const { classes } = this.props;
    const { selectedRequest } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              <GridItem xs={12} sm={3}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={selectedRequest === REQUEST_TYPE.MONITORING}
                    tabIndex={-1}
                    color="primary"
                    onClick={this.handleChange(REQUEST_TYPE.MONITORING)}
                    checkedIcon={
                      <i className={"fas fa-tv " + classes.iconCheckboxIcon} />
                    }
                    icon={
                      <i className={"fas fa-tv " + classes.iconCheckboxIcon} />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <Typography
                    color={
                      selectedRequest === REQUEST_TYPE.MONITORING
                        ? "primary"
                        : "initial"
                    }
                    variant="body1"
                  >
                    Monitoring
                  </Typography>
                </div>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={selectedRequest === REQUEST_TYPE.ESCORT}
                    color="primary"
                    tabIndex={-1}
                    onClick={this.handleChange(REQUEST_TYPE.ESCORT)}
                    checkedIcon={
                      <i className={"fas fa-car " + classes.iconCheckboxIcon} />
                    }
                    icon={
                      <i className={"fas fa-car " + classes.iconCheckboxIcon} />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <Typography
                    color={
                      selectedRequest === REQUEST_TYPE.ESCORT
                        ? "primary"
                        : "initial"
                    }
                    variant="body1"
                  >
                    Escort
                  </Typography>
                </div>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={selectedRequest === REQUEST_TYPE.STATIC}
                    color="primary"
                    tabIndex={-1}
                    onClick={this.handleChange(REQUEST_TYPE.STATIC)}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-warehouse " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-warehouse " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />
                  <Typography
                    color={
                      selectedRequest === REQUEST_TYPE.STATIC
                        ? "primary"
                        : "initial"
                    }
                    variant="body1"
                  >
                    Static
                  </Typography>
                </div>
              </GridItem>
              <GridItem xs={12} sm={3}>
                <div className={classes.choiche}>
                  <Checkbox
                    checked={selectedRequest === REQUEST_TYPE.INTERVENTION}
                    color="primary"
                    tabIndex={-1}
                    onClick={this.handleChange(REQUEST_TYPE.INTERVENTION)}
                    checkedIcon={
                      <i
                        className={
                          "fas fa-tachometer-alt " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    icon={
                      <i
                        className={
                          "fas fa-tachometer-alt " + classes.iconCheckboxIcon
                        }
                      />
                    }
                    classes={{
                      checked: classes.iconCheckboxChecked,
                      root: classes.iconCheckbox,
                    }}
                  />

                  <Typography
                    color={
                      selectedRequest === REQUEST_TYPE.INTERVENTION
                        ? "primary"
                        : "initial"
                    }
                    variant="body1"
                  >
                    Intervention
                  </Typography>
                </div>
              </GridItem>
              {selectedRequest === REQUEST_TYPE.ESCORT && (
                <GridItem style={{ float: "left" }} xs={12} sm={12} md={12}>
                  <FormControlLabel
                    style={{ marginLeft: 10 }}
                    control={
                      <Radio
                        checked={!this.state.isTakeOver}
                        onChange={() => this.setState({ isTakeOver: false })}
                        value={true}
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="It is not a take over"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.isTakeOver}
                        onChange={() => this.setState({ isTakeOver: true })}
                        value={false}
                        name="radio button demo"
                        aria-label="A"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={"It is a take over"}
                  />
                </GridItem>
              )}
              {selectedRequest !== REQUEST_TYPE.ESCORT && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
                  <Datetime
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/YYYY"
                    value={this.state.startDate}
                    isValidDate={this.validation}
                    onChange={(date) => this.onDataChanged("startDate", date)}
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return (
                        <CustomInput
                          id="success"
                          labelText="Start Date"
                          helperText={
                            this.state.startDateState === "error" &&
                            "start date is not valid or expired"
                          }
                          value={props.value}
                          success={this.state.startDateState === "success"}
                          error={this.state.startDateState === "error"}
                          inputProps={{
                            onChange: props.onChange,
                            onFocus: () => openCalendar(),
                          }}
                        />
                      );
                    }}
                  />
                </GridItem>
              )}
              {selectedRequest === REQUEST_TYPE.MONITORING && (
                <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
                  <Datetime
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/YYYY"
                    value={this.state.toDate}
                    isValidDate={this.validationToDate}
                    onChange={(date) => this.onDataChanged("toDate", date)}
                    renderInput={(props, openCalendar, closeCalendar) => {
                      return (
                        <CustomInput
                          id="success"
                          labelText="To Date (optional)"
                          helperText={
                            this.state.toDateState === "error" &&
                            "to date is not valid"
                          }
                          value={props.value}
                          success={this.state.toDateState === "success"}
                          error={this.state.toDateState === "error"}
                          inputProps={{
                            onChange: props.onChange,
                            onFocus: () => openCalendar(),
                          }}
                        />
                      );
                    }}
                  />
                </GridItem>
              )}
              {selectedRequest === REQUEST_TYPE.MONITORING && (
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                  <CustomPlaceInput
                    id="success"
                    labelText="Pick up point"
                    value={this.state.pup}
                    helperText={
                      this.state.pupState === "error" &&
                      "pick up point is required"
                    }
                    success={this.state.pupState === "success"}
                    error={this.state.pupState === "error"}
                    onChange={(text) => this.onDataChanged("pup", text)}
                  />
                </GridItem>
              )}
              {selectedRequest === REQUEST_TYPE.ESCORT && (
                <>
                  <GridContainer
                    style={
                      this.state.isTakeOver
                        ? {
                            border: "1px solid " + primaryColor,
                            paddingTop: 10,
                            paddingBottom: 10,
                          }
                        : {}
                    }
                  >
                    <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
                      <Datetime
                        timeFormat="HH:mm"
                        dateFormat="DD/MM/YYYY"
                        value={this.state.startDate}
                        isValidDate={this.validation}
                        onChange={(date) =>
                          this.onDataChanged("startDate", date)
                        }
                        renderInput={(props, openCalendar, closeCalendar) => {
                          return (
                            <CustomInput
                              id="success"
                              labelText="Start Date"
                              helperText={
                                this.state.startDateState === "error" &&
                                "start date is not valid or expired"
                              }
                              value={props.value}
                              success={this.state.startDateState === "success"}
                              error={this.state.startDateState === "error"}
                              inputProps={{
                                onChange: props.onChange,
                                onFocus: () => openCalendar(),
                              }}
                            />
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                      <CustomPlaceInput
                        id="success"
                        labelText="Pick up point"
                        value={this.state.pup}
                        helperText={
                          this.state.pupState === "error" &&
                          "pick up point is required"
                        }
                        success={this.state.pupState === "success"}
                        error={this.state.pupState === "error"}
                        onChange={(text) => this.onDataChanged("pup", text)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                      <CustomPlaceInput
                        id="success"
                        labelText="Drop off point"
                        value={this.state.dop}
                        helperText={
                          this.state.dopState === "error" &&
                          "drop off point is required"
                        }
                        success={this.state.dopState === "success"}
                        error={this.state.dopState === "error"}
                        onChange={(text) => this.onDataChanged("dop", text)}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.state.isTakeOver && (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      justify="center"
                      center
                      style={{ marginTop: 10 }}
                    >
                      <ImportExportIcon />
                    </GridItem>
                  )}
                  {this.state.isTakeOver && (
                    <GridContainer
                      style={{
                        border: "1px solid " + primaryColor,
                        paddingTop: 10,
                        paddingBottom: 10,
                        marginTop: 0,
                      }}
                    >
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: 20 }}
                      >
                        <Datetime
                          timeFormat="HH:mm"
                          dateFormat="DD/MM/YYYY"
                          isValidDate={this.validation}
                          value={this.state.takeOverStartDate}
                          onChange={(date) =>
                            this.onDataChanged("takeOverStartDate", date)
                          }
                          renderInput={(props, openCalendar, closeCalendar) => {
                            return (
                              <CustomInput
                                id="success"
                                labelText="Take over start Date"
                                helperText={
                                  this.state.takeOverStartDateState ===
                                    "error" && "start date is not valid"
                                }
                                value={props.value}
                                success={
                                  this.state.takeOverStartDateState ===
                                  "success"
                                }
                                error={
                                  this.state.takeOverStartDateState === "error"
                                }
                                inputProps={{
                                  onChange: props.onChange,
                                  onFocus: () => openCalendar(),
                                }}
                              />
                            );
                          }}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <CustomPlaceInput
                          id="success"
                          labelText="Pick up point"
                          value={this.state.takeOverPup}
                          helperText={
                            this.state.takeOverPupState === "error" &&
                            "pick up point is required"
                          }
                          success={this.state.takeOverPupState === "success"}
                          error={this.state.takeOverPupState === "error"}
                          onChange={(text) =>
                            this.onDataChanged("takeOverPup", text)
                          }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomPlaceInput
                          id="success"
                          labelText="Drop off point"
                          value={this.state.takeOverDop}
                          helperText={
                            this.state.takeOverDopState === "error" &&
                            "drop off point is required"
                          }
                          success={this.state.takeOverDopState === "success"}
                          error={this.state.takeOverDopState === "error"}
                          onChange={(text) =>
                            this.onDataChanged("takeOverDop", text)
                          }
                        />
                      </GridItem>
                    </GridContainer>
                  )}
                </>
              )}

              {selectedRequest === REQUEST_TYPE.STATIC && (
                <>
                  <GridItem xs={12} sm={12} md={6} style={{ marginTop: 20 }}>
                    <Datetime
                      timeFormat="HH:mm"
                      dateFormat="DD/MM/YYYY"
                      value={this.state.toDate}
                      isValidDate={this.validationToDate}
                      onChange={(date) => this.onDataChanged("toDate", date)}
                      renderInput={(props, openCalendar, closeCalendar) => {
                        return (
                          <CustomInput
                            id="success"
                            labelText="To Date"
                            helperText={
                              this.state.toDateState === "error" &&
                              "to date is not valid"
                            }
                            value={props.value}
                            success={this.state.toDateState === "success"}
                            error={this.state.toDateState === "error"}
                            inputProps={{
                              onChange: props.onChange,
                              onFocus: () => openCalendar(),
                            }}
                          />
                        );
                      }}
                    />
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={6} style={{ marginTop: 10 }}>
                    <InputLabel style={{ cursor: "pointer", float: "left" }}>
                      From Date
                    </InputLabel>
                    <Datetime timeFormat="HH:mm" inputProps={{ placeholder: "Start Date" }} />
                  </GridItem> */}
                  <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                    <CustomPlaceInput
                      id="success"
                      labelText="Address"
                      value={this.state.pup}
                      helperText={
                        this.state.pupState === "error" && "address is required"
                      }
                      success={this.state.pupState === "success"}
                      error={this.state.pupState === "error"}
                      onChange={(text) => this.onDataChanged("pup", text)}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      labelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>
                </>
              )}
              {selectedRequest === REQUEST_TYPE.INTERVENTION && (
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                  <CustomPlaceInput
                    id="success"
                    labelText="Location"
                    value={this.state.pup}
                    helperText={
                      this.state.pupState === "error" && "location is required"
                    }
                    success={this.state.pupState === "success"}
                    error={this.state.pupState === "error"}
                    onChange={(text) => this.onDataChanged("pup", text)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              )}
              {selectedRequest === REQUEST_TYPE.ESCORT && (
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                  <h4 style={{ float: "left" }}>
                    How many escort vehicles you need ?
                  </h4>
                  <CustomInput
                    id="success"
                    //labelText="Number of guards per"
                    placeholder="number of escort vehicles"
                    value={this.state.guardsPerEscort}
                    helperText={
                      this.state.guardsPerEscortState === "error" &&
                      "Number of escort vehicles is required"
                    }
                    type="number"
                    success={this.state.guardsPerEscortState === "success"}
                    error={this.state.guardsPerEscortState === "error"}
                    onChange={(text) =>
                      this.onDataChanged("guardsPerEscort", text)
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{
                      shrink: true,
                    }}
                  />
                  <Typography variant="body2" color="primary">
                    We provide 2 security agents per vehicle, if you want to
                    change our standard please add it to the note
                  </Typography>
                </GridItem>
              )}
              {selectedRequest === REQUEST_TYPE.INTERVENTION && (
                <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                  <h4 style={{ float: "left" }}>
                    How many security agents you need ?
                  </h4>
                  <CustomInput
                    id="success"
                    //labelText="Number of guards per"
                    placeholder="number of security agents"
                    value={this.state.guardsPerEscort}
                    helperText={
                      this.state.guardsPerEscortState === "error" &&
                      "Number of security agents is required"
                    }
                    type="number"
                    success={this.state.guardsPerEscortState === "success"}
                    error={this.state.guardsPerEscortState === "error"}
                    onChange={(text) =>
                      this.onDataChanged("guardsPerEscort", text)
                    }
                    formControlProps={{
                      fullWidth: true,
                    }}
                    labelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(style)(Step1);

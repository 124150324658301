import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { trucksActions } from "../../redux/trucks/actions";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import NewTruckDialog from "./NewTruckDialog";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true;
}

export const TruckManagement = () => {
  const dispatch = useDispatch();
  const [isNewTruckVisible, setNewTruckVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [truck, setTruck] = useState({});
  const [plateNumberState, setPlateNumberState] = useState("");
  const { trucks, saving, savingSuccess, deleteSuccess } = useSelector(
    (state) => state.trucks
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(trucksActions.fetchTrucks());
  }, []);
  useEffect(() => {
    if (savingSuccess) {
      setNewTruckVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          title={`Truck ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Truck has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          title="Deleted!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Truck has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(trucksActions.resetSuccessDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(trucksActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = (obj) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the track.
      </SweetAlert>
    );
  };

  const onDelete = (obj) => {
    dispatch(trucksActions.deleteTruck(obj.id));
  };

  useEffect(() => {
    setData(
      trucks.map((prop) => {
        return {
          id: prop.id,
          plate_number: prop.plate_number,
          color: prop.color || "N/A",
          brand: prop.brand || "N/A",
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  let truck = trucks.find((o) => o.id === prop.id);
                  onEdit(truck);
                }}
                size="lg"
                color="primary"
                className="edit"
              >
                <EditIcon />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const obj = trucks.find((o) => o.id === prop.id);
                  warningWithConfirmMessage(obj);
                }}
                size="lg"
                color="danger"
                className="remove"
              >
                <DeleteForeverIcon />
              </Button>{" "}
            </div>
          ),
        };
      })
    );
  }, [trucks]);

  const onDataChanged = (key, value) => {
    setTruck({
      ...truck,
      [key]: value,
    });
    if (key === "plate_number") {
      if (value.length >= 5) {
        setPlateNumberState("success");
      } else {
        setPlateNumberState("error");
      }
    }
  };

  const onSubmit = () => {
    if (plateNumberState !== "error") {
      dispatch(trucksActions.saveTruck(truck));
    } else {
      setPlateNumberState("error");
    }
  };

  const onOpenDialog = () => {
    setTruck({
      plate_number: "",
      color: "",
      brand: "",
    });
    setEdit(false);
    setPlateNumberState("");
    setNewTruckVisible(true);
  };

  const onEdit = (truck) => {
    setEdit(true);
    setTruck(truck);
    setPlateNumberState("");
    setNewTruckVisible(true);
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Truck
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <LocalShippingIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Truck Management ({trucks.length})
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={(value) => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input,
                  }}
                  inputProps={{
                    value: filter ? filter.value : "",
                  }}
                />
              )}
              data={data}
              filterable
              defaultFilterMethod={filterCaseInsensitive}
              columns={[
                {
                  Header: "Plate Number",
                  accessor: "plate_number",
                },
                {
                  Header: "Brand",
                  accessor: "brand",
                },
                {
                  Header: "Color",
                  accessor: "color",
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <NewTruckDialog
        title={truck.id ? "Edit Truck" : "Add new Truck"}
        onClose={() => setNewTruckVisible(false)}
        onSubmit={onSubmit}
        onDataChanged={onDataChanged}
        data={truck}
        plateNumberState={plateNumberState}
        isModalVisible={isNewTruckVisible}
        saving={saving}
      />
      {alert}
    </GridContainer>
  );
};

export default TruckManagement;

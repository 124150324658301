import React from "react";
import { useSelector } from "react-redux";
import CustomTabs from "components/CustomTabs/CustomTabs";
import StopIcon from "@material-ui/icons/Stop";
import ImageIcon from "@material-ui/icons/Image";
import CommentIcon from "@material-ui/icons/Comment";
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import RequestTimeline from "./RequestTimeline";
import { getStatus } from "redux/requests/selectors";
import { getPictures } from "redux/requests/selectors";
import { getStops } from "redux/requests/selectors";
import { getNotes } from "redux/requests/selectors";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import RequestAgentRecords from "./RequestAgentRecords";
import RequestNewTimeline from "./RequestNewTimeline";
import RequestNewAgentRecords from "./RequestNewAgentRecords";
import RequestTracking from "views/Admin/Tracking/RequestTracking";
import RoomIcon from "@material-ui/icons/Room";
import { REQUEST_STATUS } from "../../../constants";

const RequestNewHistory = (props) => {
  const status = useSelector(getStatus);
  const stops = useSelector(getStops);
  const pictures = useSelector(getPictures);
  const notes = useSelector(getNotes);

  let tabs = [
    {
      tabName: "Status",
      tabIcon: TrackChangesIcon,
      tabContent: (
        <RequestNewTimeline
          {...props}
          isOffice={props.isOffice}
          icon={TrackChangesIcon}
          data={status}
        />
      ),
    },
    {
      tabName: "Stops",
      tabIcon: StopIcon,
      tabContent: (
        <RequestNewTimeline
          {...props}
          isOffice={props.isOffice}
          icon={StopIcon}
          data={stops}
        />
      ),
    },
    {
      tabName: "Pictures/Files",
      tabIcon: ImageIcon,
      tabContent: (
        <RequestNewTimeline
          {...props}
          isOffice={props.isOffice}
          type="picture"
          icon={ImageIcon}
          data={pictures}
        />
      ),
    },
    {
      tabName: "Notes",
      tabIcon: CommentIcon,
      tabContent: (
        <RequestNewTimeline
          {...props}
          isOffice={props.isOffice}
          icon={CommentIcon}
          data={notes}
        />
      ),
    },
  ];

  tabs.push({
    tabName: "Agents records",
    tabIcon: SettingsInputAntennaIcon,
    tabContent: (
      <RequestNewAgentRecords
        {...props}
        isOffice={props.isOffice}
        icon={CommentIcon}
        data={notes}
      />
    ),
  });
  if (
    props.request.status == REQUEST_STATUS.FINISHED ||
    props.request.status == REQUEST_STATUS.CHECKED ||
    props.request.status == REQUEST_STATUS.ARCHIVED ||
    props.request.status == REQUEST_STATUS.ACCEPTED
  ) {
    tabs.push({
      tabName: "Tracking",
      tabIcon: RoomIcon,
      tabContent: (
        <RequestTracking
          {...props}
          isOffice={props.isOffice}
          icon={CommentIcon}
          data={notes}
          archived={true}
        />
      ),
    });
  }
  return (
    <Card>
      <CustomTabs
        // title="Tasks:"
        headerColor="primary"
        tabs={tabs}
      />
    </Card>
  );
};

export default RequestNewHistory;

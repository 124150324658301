import { takeLatest, call, put, select } from "redux-saga/effects";
import { ENotificationsActionTypes } from "./actions";
import axios from "../axios";

function* getNotifications(action) {
  try {
    const { notifications, paginatedRecentNotificationsUrl } = yield select(
      (state) => state.notifications
    );
    const { pageNumber } = action.payload;
    const url =
      paginatedRecentNotificationsUrl &&
      paginatedRecentNotificationsUrl != -1 &&
      pageNumber !== 1
        ? paginatedRecentNotificationsUrl
        : `/api/notifications?sort=-id&page[number]=${pageNumber}`;
    const response = yield call([axios, axios.get], url);
    const paginatedDate = response.data.success;
    yield put({
      type: ENotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications:
          pageNumber === 1
            ? paginatedDate.data
            : [...notifications, ...(paginatedDate.data || [])],
        paginatedRecentNotificationsUrl: paginatedDate.next_page_url
          ? paginatedDate.next_page_url
          : -1,
      },
    });
  } catch (e) {
    yield put({
      type: ENotificationsActionTypes.GET_NOTIFICATIONS_FAILED,
      payload: e,
    });
  }
}
function* getUnreadNotificationsCount() {
  try {
    const response = yield call(
      [axios, axios.get],
      `/api/notifications/unread`
    );
    yield put({
      type: ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
      payload: parseInt(response.data?.count),
    });
  } catch (e) {
    yield put({
      type: ENotificationsActionTypes.GET__UNREAD_NOTIFICATIONS_FAILED,
      payload: e,
    });
  }
}
function* setResetUnreadCount() {
  try {
    yield call([axios, axios.get], `/api/notifications/unread/reset`);
    yield put({
      type: ENotificationsActionTypes.CLEAR_UNREAD_COUNT_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: ENotificationsActionTypes.CLEAR_UNREAD_COUNT_FAILED,
      payload: e,
    });
  }
}
function* updateNotifications(action) {
  try {
    let {
      unread,
      notifications,
      paginatedRecentNotificationsUrl,
    } = yield select((state) => state.notifications);

    yield put({
      type: ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS,
      payload: unread + 1,
    });
    yield put({
      type: ENotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS,
      payload: {
        paginatedRecentNotificationsUrl,
        notifications: [action.payload, ...notifications],
      },
    });
  } catch (e) {
    yield put({
      type: ENotificationsActionTypes.GET__UNREAD_NOTIFICATIONS_FAILED,
      payload: e,
    });
  }
}

function* requestsSaga() {
  yield takeLatest(
    ENotificationsActionTypes.GET_NOTIFICATIONS,
    getNotifications
  );
  yield takeLatest(
    ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS,
    getUnreadNotificationsCount
  );
  yield takeLatest(
    ENotificationsActionTypes.CLEAR_UNREAD_COUNT,
    setResetUnreadCount
  );
  yield takeLatest(
    ENotificationsActionTypes.UPDATE_NOTIFICATIONS,
    updateNotifications
  );
}

export default requestsSaga;

import React from "react";
import { Grid, Box } from "@material-ui/core";
// @material-ui/icons
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { REQUEST_STATUS, primaryColor } from "../../../../src/constants";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";

const RequestItemPending = (props) => {
  const request = props.request;

  return (
    <>
      <RequestDateItem
        isEdit={
          request.status == REQUEST_STATUS.PENDING ||
          request.status == REQUEST_STATUS.ACCEPTED
        }
        onEdit={props.onOpenEditStartDate}
        fromDate={request.start_date}
        toDate={request.end_date}
        original_start_date={request.original_start_date}
      />
      {request.take_over_pup ? (
        <>
          <RequestPlaceItem pup={request.pup} dop={request.take_over_dop} />
          <label
            style={{
              color: primaryColor,
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Take Over
          </label>
          <RequestDateItem
            isEdit={
              request.status == REQUEST_STATUS.PENDING ||
              request.status == REQUEST_STATUS.ACCEPTED
            }
            onEdit={props.onOpenEditStartDateTakeOver}
            original_start_date={request.take_over_original_start_date}
            fromDate={request.take_over_start_date}
          />

          <RequestPlaceItem pup={request.take_over_pup} dop={request.dop} />
        </>
      ) : (
        <RequestPlaceItem pup={request.pup} dop={request.dop} />
      )}
      {props.request.client_transport.length > 0 && (
        <Grid style={{ marginTop: 0 }}>
          <Box display="flex">
            <label style={{ marginTop: 10, color: "#000" }}>
              <LocalShippingIcon />
            </label>
            <label
              style={{
                fontWeight: "bold",
                color: "#333",
                marginTop: 3,
              }}
            >
              Trucks :
              <label style={{ color: "#333" }}>
                {props.request.client_transport.map((item, index) => (
                  <label
                    style={{
                      marginTop: 5,
                      display: "inline-block",
                      marginLeft: 10,
                      borderRadius: 5,
                      padding: 5,
                    }}
                    key={item.id}
                  >
                    <label
                      style={{
                        color: primaryColor,
                        fontWeight: "bold",
                      }}
                    >
                      Truck {index + 1} :
                    </label>
                    {item.truck ? (
                      <label
                        style={{
                          color: "#333",
                          marginLeft: 10,
                          fontWeight: "bold",
                        }}
                      >
                        {item.truck.plate_number}{" "}
                        {item.trailer &&
                          "( Trailer: " + item.trailer.plate_number + ")"}
                      </label>
                    ) : (
                      <label style={{ color: "red" }}>
                        {" missing information"}
                      </label>
                    )}

                    <label
                      style={{
                        color: "#333",
                        marginLeft: 20,
                        fontWeight: "bold",
                      }}
                    >
                      Driver 1 :
                      {item.driver1 ? (
                        <label
                          style={{
                            color: "#333",
                            fontWeight: "bold",
                          }}
                        >
                          {" "}
                          {item.driver1.first_name} {item.driver1.last_name}{" "}
                          {item.driver1.phone_number}
                        </label>
                      ) : (
                        <label style={{ color: "red" }}>
                          {" missing information"}
                        </label>
                      )}
                    </label>
                    {item.driver2 && (
                      <label
                        style={{
                          color: "#333",
                          marginLeft: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Driver 2 : {item.driver2.first_name}{" "}
                        {item.driver2.last_name} {item.driver2.phone_number}
                      </label>
                    )}
                  </label>
                ))}
              </label>
            </label>
          </Box>
          {!!props.request.escort_number && props.request.escort_number != 1 && (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <label
                style={{
                  color: "#fff",
                  padding: 5,
                  borderRadius: 10,
                  backgroundColor: "red",
                }}
              >
                Teams : {props.request.escort_number}
              </label>
            </div>
          )}
        </Grid>
      )}
    </>
  );
};

export default RequestItemPending;

import { createSelector } from "reselect";

const currentChannel = (state) => state.chat.currentChannel;
const messages = (state) => state.chat.messages;
const activeChannels = (state) => state.chat.activeChannels;
const recentChannels = (state) => state.chat.recentChannels;
const unreadMessages = (state) => state.chat.unreadMessages;

export const getMessages = createSelector(
  [currentChannel, messages],
  (currentChannel, messages) => {
    return currentChannel && messages[currentChannel.channel_id]
      ? messages[currentChannel.channel_id]
      : [];
  }
);
export const getUnreadActiveMessagesCount = createSelector(
  [activeChannels, unreadMessages],
  (activeChannels, unreadMessages) => {
    let count = 0;
    for (let i = 0; i < unreadMessages.length; i++) {
      const unreadMessage = unreadMessages[i];
      if (
        activeChannels.find((x) => x.channel_id == unreadMessage.channel_id)
      ) {
        count += parseInt(unreadMessage.total);
      }
    }
    return count;
  }
);
export const getUnreadRecentMessagesCount = createSelector(
  [recentChannels, unreadMessages],
  (recentChannels, unreadMessages) => {
    let count = 0;
    for (let i = 0; i < unreadMessages.length; i++) {
      const unreadMessage = unreadMessages[i];
      if (
        recentChannels.find((x) => x.channel_id == unreadMessage.channel_id)
      ) {
        count += parseInt(unreadMessage.total);
      }
    }
    return count;
  }
);
export const getAllUnreadMessagesCount = createSelector(
  [unreadMessages],
  (unreadMessages) => {
    let count = 0;
    for (let i = 0; i < unreadMessages.length; i++) {
      const unreadMessage = unreadMessages[i];
      count += parseInt(unreadMessage.total);
    }
    return count;
  }
);

export const getSortedActiveChannels = createSelector(
  [activeChannels],
  (activeChannels) => activeChannels.sort(compareByLastMessage)
);
export const getSortedRecentChannels = createSelector(
  [recentChannels],
  (recentChannels) => recentChannels.sort(compareByLastMessage)
);

export function compareByLastMessage(a, b) {
  const lastMessageA = a.channels && a.channels.last_message;
  const lastMessageB = b.channels && b.channels.last_message;
  if (lastMessageA && lastMessageB) {
    if (lastMessageA.created_at > lastMessageB.created_at) return -1;
    if (lastMessageB.created_at > lastMessageA.created_at) return 1;
  }
  if (lastMessageA && !lastMessageB) return -1;
  if (!lastMessageA && lastMessageB) return 1;
  // if (!lastMessageA && !lastMessageB) {
  //   if (a.channels.created_at > b.channels.created_at) return -1;
  //   if (b.channels.created_at > a.channels.created_at) return 1;
  // }

  return 0;
}

import React from "react";
import { useSelector } from "react-redux";
import { isImage } from "redux/utils";
import File from "../../../assets/img/file.png";

const ChannelImages = () => {
  const { images, requestsChannel: requests } = useSelector(
    (state) => state.requests
  );

  return (
    <div className="shift-frame" style={{ alignItems: "center" }}>
      {images.map((item) => {
        const request = requests.find((x) => x.id == item.request_id);
        if (request)
          return (
            <div
              className="request_card"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_IMAGE_URL}requests/'${request.type}_${request.identifier}/${item.image}`
                )
              }
            >
              {isImage(item.image) ? (
                <img
                  resizeMode="contain"
                  style={{
                    height: 250,
                    marginTop: 10,
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                  src={`${process.env.REACT_APP_IMAGE_URL}requests/'${request.type}_${request.identifier}/${item.image}`}
                />
              ) : (
                <div
                  style={{
                    height: 250,
                    marginTop: 10,
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                >
                  <img
                    style={{
                      marginTop: 10,
                      display: "block",
                      "margin-left": "auto",
                      "margin-right": "auto",
                    }}
                    src={File}
                    width={100}
                    height={100}
                  />
                  <div style={{ textAlign: "center" }}>{item.image}</div>
                </div>
              )}
            </div>
          );

        return (
          <div
            className="request_card"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_IMAGE_URL}channels/channel_${item.channel_id}/${item.image}`
              )
            }
          >
            {isImage(item.image) ? (
              <img
                resizeMode="contain"
                style={{
                  height: 250,
                  marginTop: 10,
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                }}
                src={`${process.env.REACT_APP_IMAGE_URL}channels/channel_${item.channel_id}/${item.image}`}
              />
            ) : (
              <div>
                <img
                  style={{
                    marginTop: 10,
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  }}
                  src={File}
                  width={100}
                  height={100}
                />
                <div style={{ textAlign: "center" }}>{item.image}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ChannelImages;

import { all, call, fork } from "redux-saga/effects";
import requests from "./requests/saga";
import trucks from "./trucks/saga";
import trailers from "./trailers/saga";
import drivers from "./drivers/saga";
import user from "./user/saga";
import cars from "./cars/saga";
import agents from "./agents/saga";
import clients from "./clients/saga";
import branches from "./branches/saga";
import accounts from "./accounts/saga";
import chat from "./chat/saga";
import subcaccounts from "./subcaccounts/saga";
import subcontractors from "./subcontractors/saga";
import notifications from "./notifications/saga";
import { isClient, isAdmin } from "../constants";

const sagas = isClient
  ? [user, requests, drivers, trucks, trailers, chat]
  : isAdmin
  ? [
      user,
      requests,
      cars,
      agents,
      clients,
      branches,
      accounts,
      subcaccounts,
      subcontractors,
      chat,
      notifications,
    ]
  : [user, requests, cars, agents, chat];

function* wrapForErrorHandling(fn: (...args: any[]) => void) {
  try {
    yield call(fn, { restarted: false });
  } catch (e) {
    // TODO: trigger APP_CRASH event to handle it in appSaga and send crashReport to crashlytics
    // tslint:disable-next-line:no-console
  }
}

function* rootSaga() {
  yield all(sagas.map((saga) => fork(wrapForErrorHandling, saga)));
}

export default rootSaga;

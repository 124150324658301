import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { clientsActions } from "../../redux/clients/actions";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NewClientDialog from "./NewClientDialog";
import { IMAGE_URL } from "../../../src/constants";
import { history } from "../../redux/store";
import defaultImage from "assets/img/image_placeholder.jpg";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true;
}

export const ClientManagement = () => {
  const dispatch = useDispatch();
  const [isNewClientVisible, setNewClientVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [client, setClient] = useState({});
  const [nameState, setNameState] = useState("");
  const { clients, saving, savingSuccess, deleteSuccess } = useSelector(
    (state) => state.clients
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(clientsActions.fetchClients());
  }, []);

  useEffect(() => {
    if (savingSuccess) {
      setNewClientVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          title={`Client ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Client has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          title="Deleted!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Client has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(clientsActions.resetSuccessDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(clientsActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = (obj) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the client.
      </SweetAlert>
    );
  };

  const onDelete = (obj) => {
    dispatch(clientsActions.deleteClient(obj.id));
  };

  const onDataChanged = (key, value) => {
    setClient({
      ...client,
      [key]: value,
    });
    if (key === "name") {
      if (value) {
        setNameState("success");
      } else {
        setNameState("error");
      }
    }
  };

  const onSubmit = () => {
    if (nameState === "success") {
      if (client.file) {
        dispatch(clientsActions.saveClientWithLogo(client));
      } else {
        dispatch(clientsActions.saveClient(client));
      }
    } else {
      setNameState("error");
    }
  };

  const onOpenDialog = () => {
    setClient({
      name: "",
      logo: "",
    });
    setEdit(false);
    setNameState("");
    setNewClientVisible(true);
  };

  const onEdit = (client) => {
    setEdit(true);
    setClient(client);
    setNameState("success");
    setNewClientVisible(true);
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Client
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <BusinessCenterIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Client Management ({clients.length})
            </h4>
          </CardHeader>
          <CardBody />
        </Card>
        <GridContainer>
          {!!clients &&
            clients.map((client) => (
              <GridItem key={client.id} xs={6} sm={4} md={3}>
                <Card style={{ float: "right" }}>
                  <Box
                    display="flex"
                    className="actions-right"
                    style={{
                      justifyContent: "flex-end",
                      float: "right",
                      width: "100%",
                      marginRight: 10,
                      justifyContent: "flex-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => onEdit(client)}
                        size="lg"
                        color="primary"
                        className="edit"
                      >
                        <EditIcon />
                      </Button>{" "}
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => warningWithConfirmMessage(client)}
                        size="lg"
                        color="danger"
                        className="remove"
                      >
                        <DeleteForeverIcon />
                      </Button>{" "}
                    </Box>
                  </Box>
                  <img
                    onClick={() => history.push(`/admin/branches/${client.id}`)}
                    style={{
                      cursor: "pointer",
                      objectFit: "contain",
                      margin: 20,
                      marginTop: 0,
                    }}
                    src={
                      client.logo
                        ? IMAGE_URL + "clients/" + client.logo
                        : defaultImage
                    }
                    height={200}
                  />
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ textAlign: "center", marginBottom: 10 }}
                  >
                    {client.name}
                  </Typography>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridItem>
      {isNewClientVisible && (
        <NewClientDialog
          title={client.id ? "Edit Client" : "Add new Client"}
          onClose={() => setNewClientVisible(false)}
          onSubmit={onSubmit}
          onDataChanged={onDataChanged}
          data={client}
          nameState={nameState}
          isModalVisible={isNewClientVisible}
          saving={saving}
        />
      )}
      {alert}
    </GridContainer>
  );
};

export default ClientManagement;

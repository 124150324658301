import { takeLatest, call, put, select, take } from "redux-saga/effects";
import { chatActions, EChatActionTypes } from "./actions";
import axios from "../axios";
import { EUserActionTypes } from "../user/actions";
import { getMessages } from "./selectors";
import { audioRef } from "index";
import { isImage } from "redux/utils";
// import NavigationService from "../../NavigationService";
function* getMessagesHistory(action) {
  try {
    const { messages, currentChannel } = yield select((state) => state.chat);
    let channelMessages = messages[currentChannel.channel_id] || [];
    const pageNumber = action.payload;
    const url =
      messages["nextPageUrl"] && messages["nextPageUrl"] != -1
        ? messages["nextPageUrl"]
        : `/api/messages/${currentChannel.channel_id}?sort=-id&page[number]=${pageNumber}`;
    const response = yield call([axios, axios.get], url);
    const paginatedDate = response.data.success;
    // console.log('response : ', response);
    yield put({
      type: EChatActionTypes.GET_MESSAGES_HISTORY_SUCCESS,
      payload: paginatedDate,
    });

    yield put({
      type: EChatActionTypes.SET_MESSAGES_HISTORY_SUCCESS,
      payload: {
        messages:
          pageNumber === 1
            ? paginatedDate.data
            : [...channelMessages, ...(paginatedDate.data || [])],
        channelId: currentChannel.channel_id,
        nextPageUrl: paginatedDate.next_page_url
          ? paginatedDate.next_page_url
          : -1,
      },
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.GET_MESSAGES_HISTORY_FAILED,
      payload: e,
    });
  }
}
function* getRecentChannels(action) {
  try {
    const { recentChannels, paginatedRecentChannelsUrl } = yield select(
      (state) => state.chat
    );
    const { pageNumber, search } = action.payload;
    const url =
      paginatedRecentChannelsUrl &&
      paginatedRecentChannelsUrl != -1 &&
      pageNumber !== 1
        ? paginatedRecentChannelsUrl + "&search=" + search
        : `/api/channels/recent?search=${search}&sort=-id&page[number]=${pageNumber}`;
    const response = yield call([axios, axios.get], url);
    const paginatedDate = response.data.success;
    // yield put({
    //   type: EChatActionTypes.SEARCH_RECENT_CHANNELS_SUCCESS,
    //   payload: paginatedDate,
    // });
    // console.log('response.data.success.data : ', paginatedDate.data);
    yield put({
      type: EChatActionTypes.GET_RECENT_CHANNELS_SUCCESS,
      payload: {
        recentChannels:
          pageNumber === 1
            ? paginatedDate.data
            : [...recentChannels, ...(paginatedDate.data || [])],
        paginatedRecentChannelsUrl: paginatedDate.next_page_url
          ? paginatedDate.next_page_url
          : -1,
      },
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.GET_RECENT_CHANNELS_FAILED,
      payload: e,
    });
  }
}
function* getActiveChannels() {
  try {
    const response = yield call([axios, axios.get], "/api/channels/active");
    yield put({
      type: EChatActionTypes.GET_ACTIVE_CHANNELS_SUCCESS,
      payload: [...response.data.success],
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.GET_ACTIVE_CHANNELS_FAILED,
      payload: e.response,
    });
  }
}
function* updateChannelGroup(action) {
  try {
    const response = yield call(
      [axios, axios.put],
      "/api/channels/group/" + action.payload.id,
      { muted: action.payload.muted }
    );
    let chat = yield select((state) => state.chat);
    const channels = chat[action.payload.request_status + "Channels"];
    // console.log('channels : ', channels);
    const channelGroupIndex = channels.findIndex(
      (x) => x.id == action.payload.id
    );
    channels[channelGroupIndex].muted = action.payload.muted;

    // console.log('response channels : ', channels);
    yield put({
      type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
      payload: {
        channels: [...channels],
        request_status: action.payload.request_status,
      },
    });
    yield put({
      type: EChatActionTypes.UPDATE_CHANNEL_GROUP_SUCCESS,
      payload: response.data.success,
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.UPDATE_CHANNEL_GROUP_FAILED,
      payload: e,
    });
  }
}
function* setChannelDetails(action) {
  try {
    const { id, channelName, request_status } = action.payload;
    const response = yield call([axios, axios.put], "/api/channels/" + id, {
      channelName,
      request_status,
    });
    let chat = yield select((state) => state.chat);
    const { currentChannel } = chat;
    if (currentChannel) {
      const channels = chat[request_status + "Channels"];
      // console.log('channels : ', channels);
      const channelGroupIndex = channels.findIndex((x) => x.channels.id == id);
      channels[channelGroupIndex].channels.name = channelName;
      currentChannel.channels.name = channelName;

      // console.log('response channels : ', channels);
      yield put({
        type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
        payload: {
          channels: [...channels],
          request_status,
        },
      });
      yield put({
        type: EChatActionTypes.SET_CURRENT_CHANNEL,
        payload: currentChannel,
      });
      yield put({
        type: EChatActionTypes.SET_CHANNEL_DETAILS_SUCCESS,
        payload: action.payload,
      });
    }
  } catch (e) {
    yield put({
      type: EChatActionTypes.SET_CHANNEL_DETAILS_FAILED,
      payload: e,
    });
  }
}
function* getUpdatedChannel(action) {
  try {
    const { data } = action.payload;
    let chat = yield select((state) => state.chat);
    const { currentChannel } = chat;
    const channels = chat[data.request_status + "Channels"];
    // console.log('channels : ', channels);
    const channelGroupIndex = channels.findIndex(
      (x) => x.channels && x.channels.id == data.channel_id
    );
    if (channelGroupIndex != -1) {
      channels[channelGroupIndex].channels.name = data.channel_name;
      if (currentChannel) {
        currentChannel.channels.name = data.channel_name;
        yield put({
          type: EChatActionTypes.SET_CURRENT_CHANNEL,
          payload: currentChannel,
        });
      }
      yield put({
        type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
        payload: {
          channels: [...channels],
          request_status: data.request_status,
        },
      });
      yield put({
        type: EChatActionTypes.SET_NOTIFICATION_MESSAGE,
        payload: data,
      });
      yield put(chatActions.getNewMessage(data));
    }
  } catch (e) {
    yield put({
      type: EChatActionTypes.SET_CHANNEL_DETAILS_FAILED,
      payload: e,
    });
  }
}
function* addImageRequest(action) {
  try {
    const response = yield call(
      [axios, axios.post],
      "/api/agent/request/history",
      action.payload
    );
    // console.log('response : ', response);
    yield put({
      type: EChatActionTypes.ADD_IMAGE_REQUEST_HISTORY_SUCCESS,
      payload: response.data.success,
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.ADD_IMAGE_REQUEST_HISTORY_FAILED,
      payload: e.response,
    });
  }
}
function* getChannelMembers(action) {
  try {
    const { currentChannel } = yield select((state) => state.chat);
    const channelId = action.payload;
    const response = yield call(
      [axios, axios.get],
      `/api/channels/${channelId}`
    );

    yield put({
      type: EChatActionTypes.GET_CHANNEL_MEMBERS_SUCCESS,
      payload: response.data.success,
    });
    yield put({
      type: EChatActionTypes.SET_CURRENT_CHANNEL,
      payload: {
        ...currentChannel,
        members: response.data.success.members,
      },
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.GET_CHANNEL_MEMBERS_FAILED,
      payload: e.response,
    });
  }
}
function* getUnreadMessage() {
  try {
    const response = yield call([axios, axios.get], "/api/messages/unread");

    yield put({
      type: EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS,
      payload: response.data.success,
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.GET_UNREAD_MESSAGE_FAILED,
      payload: e.response,
    });
  }
}
function* uploadImageHistory(action) {
  try {
    const history = action.payload;
    const formData = new FormData();

    formData.append("image", history.image);
    formData.append(
      "folder",
      history.channel_id
        ? `channels/channel_${history.channel_id}`
        : `requests/'${history.type}_${history.identifier}`
    );
    formData.append(
      "fileName",
      isImage(history.image.name)
        ? null
        : history.image.name.replace(/\s/g, "").replace(/[^\w\s.]/gi, "")
    );
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    const response = yield call(
      [axios, axios.post],
      "/api/agent/imageUpload",
      formData,
      config
    );
    if (response && response.data && response.data.success) {
      yield put({
        type: EChatActionTypes.UPLOAD_IMAGE_HISTORY_SUCCESS,
        payload: {
          logo: response.data.success,
        },
      });
    } else {
      yield put({ type: EChatActionTypes.UPLOAD_IMAGE_HISTORY_FAILED });
    }
  } catch (e) {
    console.log(e);
    yield put({
      type: EChatActionTypes.UPLOAD_IMAGE_HISTORY_FAILED,
      payload: e.response ? e.response.statusText : e,
    });
  }
}
function* sendNewMessage(action) {
  try {
    const { currentChannel } = yield select((state) => state.chat);
    const message = action.payload;
    let image = null;
    if (message.imageData) {
      const request = currentChannel.channels.requests[0].request;
      yield put(
        chatActions.uploadImageHistory({
          image: message.imageData.image,
          type: request.type,
          identifier: request.identifier,
          channel_id:
            currentChannel.channels.requests.length > 1
              ? currentChannel.channel_id
              : null,
        })
      );
      const validUploadImageAction = yield take([
        EChatActionTypes.UPLOAD_IMAGE_HISTORY_SUCCESS,
        EChatActionTypes.UPLOAD_IMAGE_HISTORY_FAILED,
      ]);
      if (
        validUploadImageAction.type ===
        EChatActionTypes.UPLOAD_IMAGE_HISTORY_FAILED
      ) {
        return;
      }
      image = validUploadImageAction.payload.logo;

      if (currentChannel.channels.requests.length == 1)
        yield put(
          chatActions.addImageRequest({
            request_id: request.id,
            image,
            reason: message.imageData.reason,
            history_status: "IMAGE",
          })
        );
    }
    const channelMessages = yield select(getMessages);
    delete action.payload.imageData;
    action.payload.image = image;
    const tempId = Math.random();
    yield put({
      type: EChatActionTypes.GET_NEW_MESSAGE,
      payload: {
        ...action.payload,
        tempId,
        isSent: true,
      },
    });
    const members = currentChannel.members;
    // const members = currentChannel.members.filter(
    //   (x) => x.user_id != message.user.id
    // );
    const body = {
      sender_id: message.user.id,
      channel_id: message.channel_id,
      text: message.text,
      image: message.image,
      channel_name: message.name,
      request_status: message.request_status,
      members: members.map((x) => ({
        id: x.user_id,
        channel_group_id: x.id,
        language: x?.user?.language,
      })),
      tempId,
    };
    const response = yield call(
      [axios, axios.post],
      `/api/messages/${message.channel_id}`,
      body
    );
    // console.log('body : ', body);
    const currentMessageIndex = channelMessages.findIndex(
      (x) => x.id == message.id
    );
    channelMessages[currentMessageIndex] = {
      ...channelMessages[currentMessageIndex],
      message_status: members.map((x) => ({ user_id: x.user_id, status: 0 })),
      id: response.data.success.id,
    };
    yield put({
      type: EChatActionTypes.SEND_NEW_MESSAGE_SUCCESS,
      payload: response.data.success,
    });
    yield put({
      type: EChatActionTypes.SET_MESSAGES_SUCCESS,
      payload: { messages: channelMessages, channelId: message.channel_id },
    });
  } catch (e) {
    console.log(e);
    yield put({
      type: EChatActionTypes.SEND_NEW_MESSAGE_FAILED,
      payload: e.response,
    });
  }
}
function* setReadAllMessages(action) {
  try {
    const channelId = action.payload;
    const { unreadMessages } = yield select((state) => state.chat);
    yield call([axios, axios.put], `/api/messages/read/all/${channelId}`);
    yield put({
      type: EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS,
      payload: unreadMessages.filter((x) => x.channel_id != channelId),
    });

    yield put({
      type: EChatActionTypes.SET_READ_ALL_MESSAGES_SUCCESS,
      payload: channelId,
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.SET_READ_ALL_MESSAGES_FAILED,
      payload: e.response,
    });
  }
}
function* readMessageById(action) {
  try {
    const messageId = action.payload;
    yield call([axios, axios.put], `/api/messages/read/${messageId}`);
    yield put({
      type: EChatActionTypes.READ_MESSAGE_BY_ID_SUCCESS,
      payload: messageId,
    });
  } catch (e) {
    yield put({
      type: EChatActionTypes.READ_MESSAGE_BY_ID_FAILED,
      payload: e.response,
    });
  }
}
function* createNewChannel(action) {
  try {
    const { activeChannels } = yield select((state) => state.chat);

    const response = yield call(
      [axios, axios.post],
      `/api/channels`,
      action.payload
    );
    if (response.data && response.data.success) {
      yield put({
        type: EChatActionTypes.CREATE_NEW_CHANNEL_SUCCESS,
        payload: response.data.success,
      });
      yield put({
        type: EChatActionTypes.GET_ACTIVE_CHANNELS_SUCCESS,
        payload: [response.data.success, ...activeChannels],
      });
    } else {
      yield put({
        type: EChatActionTypes.CREATE_NEW_CHANNEL_FAILED,
        payload: "Something went wrong!",
      });
    }
  } catch (e) {
    yield put({
      type: EChatActionTypes.CREATE_NEW_CHANNEL_FAILED,
      payload: e.response,
    });
  }
}

function* handleChannelMessage(
  channelMessages,
  newMessage,
  channelId,
  currentChannel,
  destinatedChannelIndex
) {
  const chat = yield select((state) => state.chat);
  const channels = chat[newMessage.request_status + "Channels"];
  const unreadMessages = chat.unreadMessages;
  const { user } = yield select((state) => state.user);
  const members = currentChannel
    ? currentChannel.members
    : [{ user_id: user.id }];
  if (user.id == newMessage.user.id && !newMessage.isSent) {
    newMessage.message_status = members.map((x) => ({
      user_id: x.user_id,
      status: 0,
    }));
  }
  // user is in the current chat channel
  if (channelMessages) channelMessages.unshift(newMessage);
  else {
    channelMessages = [newMessage];
  }
  yield put({
    type: EChatActionTypes.SET_MESSAGES_SUCCESS,
    payload: { messages: channelMessages, channelId },
  });
  if (
    !currentChannel ||
    currentChannel.channel_id != channelId
    // &&
    // newMessage.user.id != user.id
  ) {
    // user is in different chat channel
    // console.log('show notification');
    if (
      destinatedChannelIndex != -1 &&
      channels[destinatedChannelIndex].muted == 0 &&
      !newMessage.push_notification
    ) {
      const channelGroup = channels[destinatedChannelIndex];
      const body = {
        ...newMessage,
        type:
          channelGroup.channels.requests.length === 1
            ? channelGroup.channels.requests[0].type
            : null,
        identifier:
          channelGroup.channels.requests.length === 1
            ? channelGroup.channels.requests[0].identifier
            : null,
      };

      yield put({
        type: EChatActionTypes.SET_NOTIFICATION_MESSAGE,
        payload: body,
      });
    }

    if (newMessage.user.id != user.id) {
      const unreadChannelMessagesIndex = unreadMessages.findIndex(
        (x) => x.channel_id == channelId
      );
      if (unreadChannelMessagesIndex != -1) {
        unreadMessages[unreadChannelMessagesIndex].total =
          parseInt(unreadMessages[unreadChannelMessagesIndex].total) + 1 + "";
      } else {
        unreadMessages.push({
          total: "1",
          channel_id: channelId,
        });
      }
      yield put({
        type: EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS,
        payload: [...unreadMessages],
      });
      if (channels[destinatedChannelIndex].muted == 0 && audioRef.current)
        audioRef.current.play();
    }
  } else {
    if (chat.isChatWidgetVisible && newMessage.user.id != user.id)
      yield put(chatActions.readMessageById(newMessage.id));
    if (
      (!chat.isChatWidgetVisible || chat.isWindowActive === false) &&
      destinatedChannelIndex !== -1 &&
      channels[destinatedChannelIndex].muted == 0
    )
      if (audioRef.current) audioRef.current.play();
    if (!chat.isChatWidgetVisible) {
      const unreadChannelMessagesIndex = unreadMessages.findIndex(
        (x) => x.channel_id == channelId
      );
      if (unreadChannelMessagesIndex != -1) {
        unreadMessages[unreadChannelMessagesIndex].total =
          parseInt(unreadMessages[unreadChannelMessagesIndex].total) + 1 + "";
      } else {
        unreadMessages.push({
          total: "1",
          channel_id: channelId,
        });
      }
      yield put({
        type: EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS,
        payload: [...unreadMessages],
      });
      if (channels[destinatedChannelIndex].muted == 0 && audioRef.current)
        audioRef.current.play();
    }
  }

  // const lastMessages = [...activeChannels[destinatedChannelIndex].messages];
  // lastMessages.push(newMessage);
  if (destinatedChannelIndex != null) {
    channels[destinatedChannelIndex].channels.last_message = newMessage;
    yield put({
      type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
      payload: {
        channels,
        request_status: newMessage.request_status,
      },
    });
  }
}
function* getNewMessage(action) {
  try {
    const chat = yield select((state) => state.chat);
    const { messages, currentChannel } = chat;
    const newMessage = action.payload;
    const channels = chat[action.payload.request_status + "Channels"];
    const channelId = newMessage.channel_id;
    let channelMessages = messages[channelId];
    const isMessageExist =
      channelMessages && newMessage.id
        ? channelMessages.find(
            (x) =>
              x.id == newMessage.id ||
              (!!x.tempId && x.tempId == newMessage.tempId)
          )
        : false;
    if (!isMessageExist) {
      const destinatedChannelIndex = channels.findIndex(
        (x) => x.channels.id == channelId
      );
      //existing channel
      if (destinatedChannelIndex != -1) {
        // if (newMessage.push_notification) {
        //   const messageChannel = channels[destinatedChannelIndex];
        //   if (messageChannel) {
        //     console.log("please navigate");
        //     yield put(chatActions.setCurrentChannel(messageChannel));
        //     NavigationService.navigate("ChatMessage", messageChannel);
        //   }
        // }
        yield call(
          handleChannelMessage,
          channelMessages,
          newMessage,
          channelId,
          currentChannel,
          destinatedChannelIndex
        );
      } else {
        const response = yield call(
          [axios, axios.get],
          `/api/channels/${channelId}/group`
        );
        let channelDetails = response.data.success;
        channelDetails.channels.last_message = newMessage;

        yield put({
          type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
          payload: {
            channels: [channelDetails, ...channels],
            request_status: newMessage.request_status,
          },
        });
        // if (newMessage.push_notification) {
        //   console.log("please navigate");
        //   yield put(chatActions.setCurrentChannel(channelDetails));
        //   NavigationService.navigate("ChatMessage", channelDetails);
        // } else {
        //pusher
        yield call(
          handleChannelMessage,
          null,
          newMessage,
          channelId,
          null,
          channels.length
        );
        // }
      }
    }
  } catch (e) {
    console.log(e);
    // yield put({
    //   type: EChatActionTypes.SET_MESSAGES_SUCCESS,
    //   payload: messages,
    // });
  }
}
function* getNewChannel(action) {
  try {
    const { data, push_notification } = action.payload;
    const chat = yield select((state) => state.chat);
    const { user } = yield select((state) => state.user);
    const channels = chat[data.request_status + "Channels"];
    const channelId = data.channel_id;
    const destinatedChannelIndex = channels.findIndex(
      (x) => x.channels.id == channelId
    );
    //existing channel
    if (destinatedChannelIndex != -1) {
      if (user.id != data.created_by) {
        yield put({
          type: EChatActionTypes.SET_NOTIFICATION_MESSAGE,
          payload: data,
        });
      }
    } else {
      const response = yield call(
        [axios, axios.get],
        `/api/channels/${channelId}/group`
      );
      let channelDetails = response.data.success;
      // channelDetails.channels.last_message = null;

      yield put({
        type: EChatActionTypes.SET_ACTIVE_CHANNELS_SUCCESS,
        payload: {
          channels: [channelDetails, ...channels],
          request_status: data.request_status,
        },
      });
      // if (push_notification) {
      //   yield put(chatActions.setCurrentChannel(channelDetails));
      //   NavigationService.navigate("ChatMessage", channelDetails);
      // } else {
      if (user.id != data.created_by) {
        yield put({
          type: EChatActionTypes.SET_NOTIFICATION_MESSAGE,
          payload: data,
        });
      }
      chat.unreadMessages.push({
        total: "1",
        channel_id: channelId,
      });

      yield put({
        type: EChatActionTypes.GET_UNREAD_MESSAGE_SUCCESS,
        payload: [...chat.unreadMessages],
      });
      if (audioRef.current) audioRef.current.play();
      // }
    }
  } catch (e) {
    console.log(e);
  }
}

function* chatSaga() {
  yield takeLatest(EChatActionTypes.GET_ACTIVE_CHANNELS, getActiveChannels);
  yield takeLatest(EChatActionTypes.GET_RECENT_CHANNELS, getRecentChannels);
  yield takeLatest(EChatActionTypes.GET_NEW_MESSAGE, getNewMessage);
  yield takeLatest(EChatActionTypes.GET_NEW_CHANNEL, getNewChannel);
  yield takeLatest(EUserActionTypes.GET_USER_SUCCESS, getUnreadMessage);
  yield takeLatest(EChatActionTypes.SET_READ_ALL_MESSAGES, setReadAllMessages);
  yield takeLatest(EChatActionTypes.READ_MESSAGE_BY_ID, readMessageById);
  yield takeLatest(EChatActionTypes.SEND_NEW_MESSAGE, sendNewMessage);
  yield takeLatest(EChatActionTypes.GET_CHANNEL_MEMBERS, getChannelMembers);
  yield takeLatest(EChatActionTypes.GET_MESSAGES_HISTORY, getMessagesHistory);
  yield takeLatest(EChatActionTypes.UPLOAD_IMAGE_HISTORY, uploadImageHistory);
  yield takeLatest(EChatActionTypes.ADD_IMAGE_REQUEST_HISTORY, addImageRequest);
  yield takeLatest(EChatActionTypes.UPDATE_CHANNEL_GROUP, updateChannelGroup);
  yield takeLatest(EChatActionTypes.SET_CHANNEL_DETAILS, setChannelDetails);
  yield takeLatest(EChatActionTypes.GET_UPDATED_CHANNEL, getUpdatedChannel);
  yield takeLatest(EChatActionTypes.CREATE_NEW_CHANNEL, createNewChannel);
}

export default chatSaga;

Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

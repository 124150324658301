import React, { useState, useEffect } from "react";
import Timeline from "components/Timeline/Timeline";
import FormatQuote from "@material-ui/icons/FormatQuote";
import moment from "moment";
import {
  IMAGE_URL,
  primaryColor,
  REQUEST_HISTORY,
  REQUEST_STATUS,
  isClient,
  isAdmin,
  CURRENCIES,
} from "../../../constants";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import File from "../../../assets/img/file.png";

import Datetime from "react-datetime";
import {
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CustomInput from "components/CustomInput/CustomInput";
import { requestsActions } from "redux/requests/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import CustomAutoCompleteInput from "components/CustomAutoCompleteInput/CustomAutoCompleteInput";
import { isImage } from "redux/utils";

export const stopDuration = (date, endDate) => {
  const startDate = moment(date);
  const today = endDate ? moment(endDate) : moment();
  let delta = today.diff(startDate, "seconds");
  return moment({ h: 0, m: 0, s: 0, ms: 0 })
    .add("second", delta)
    .format("H:mm:ss");
};
const RequestNewTimeline = ({ data, icon, request, type, isOffice }) => {
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState({});
  const [alertHistory, setAlert] = useState(null);
  const { updateHistorySuccess, updateHistoryError } = useSelector(
    (state) => state.requests
  );
  const agents = useSelector((state) => state.agents);
  const cars = useSelector((state) => state.cars);
  const [isLoading, setLoading] = useState({});
  const [editedHistory, setEditedHistory] = useState({});
  const [pictureFilter, setPictureFilter] = React.useState("all");
  if (type === "picture") {
    if (pictureFilter === "Other")
      data = data.filter(
        (x) =>
          !["Truck", "Trailer", "Seal", "New seal", "Report", "CMR"].includes(
            x.reason
          )
      );
    else if (pictureFilter !== "all")
      data = data.filter((x) => x.reason === pictureFilter);
  }

  const onDataChanged = (key, value) => {
    if (key === "agent_id") {
      setEditedHistory({
        ...editedHistory,
        agent_id: value.id,
        agent: value,
      });
    } else if (key === "security_car_id") {
      setEditedHistory({
        ...editedHistory,
        security_car_id: value.id,
        security_car: value,
      });
    } else if (key === "currency") {
      setEditedHistory({
        ...editedHistory,
        currency: value.value,
        currencyObj: value,
      });
    } else {
      setEditedHistory({
        ...editedHistory,
        [key]: value,
      });
    }
  };

  const onDeleteHistory = (history) => {
    const r = window.confirm("Are you sure you wan to delete this history?");
    if (r === true) {
      dispatch(
        requestsActions.deleteRequestHistory({
          id: history.id,
          type: request.type,
          identifier: request.identifier,
        })
      );
    }
  };
  React.useEffect(() => {
    if (updateHistorySuccess) {
      const id = editedHistory.id;
      setEdit({
        ...isEdit,
        [id]: null,
      });
      setLoading({
        ...isLoading,
        [id]: null,
      });
    }
    if (updateHistoryError) {
      const id = editedHistory.id;
      setLoading({
        ...isLoading,
        [id]: null,
      });
    }
  }, [updateHistorySuccess, updateHistoryError]);
  const generateTimeLine = (data, icon, request) => {
    const stories = [];
    for (let i = 0; i < data.length; i++) {
      const history = data[i];
      const shiftNumber =
        request?.request_shift?.findIndex(
          (item) => item.id == history.request_shift_id
        ) + 1;
      if (history.currency) {
        history.currencyObj = CURRENCIES.find(
          (x) => x.value == history.currency
        );
      }

      stories.push({
        inverted: true,
        title: REQUEST_HISTORY[history.history_status],
        badgeColor:
          REQUEST_HISTORY[history.history_status] ===
            REQUEST_HISTORY.FINISHED ||
          REQUEST_HISTORY[history.history_status] === REQUEST_HISTORY.SOS
            ? "danger"
            : REQUEST_HISTORY[history.history_status] ===
                REQUEST_HISTORY.START_JOB ||
              REQUEST_HISTORY[history.history_status] ===
                REQUEST_HISTORY.START_TRIP ||
              REQUEST_HISTORY[history.history_status] === REQUEST_HISTORY.SAFE
            ? "success"
            : "primary",
        titleColor:
          REQUEST_HISTORY[history.history_status] ===
            REQUEST_HISTORY.FINISHED ||
          REQUEST_HISTORY[history.history_status] === REQUEST_HISTORY.SOS
            ? "danger"
            : REQUEST_HISTORY[history.history_status] ===
                REQUEST_HISTORY.START_JOB ||
              REQUEST_HISTORY[history.history_status] ===
                REQUEST_HISTORY.START_TRIP ||
              REQUEST_HISTORY[history.history_status] === REQUEST_HISTORY.SAFE
            ? "success"
            : "primary",
        badgeIcon: icon,
        body: (
          <div
            style={{
              backgroundColor:
                history.history_status === REQUEST_HISTORY.SOS
                  ? "#ffcccb"
                  : history.history_status === REQUEST_HISTORY.SAFE
                  ? "#ddffdd"
                  : "#fff",
              borderRadius: 5,
            }}
          >
            {isEdit[history.id] ? (
              <div>
                {history.request_team &&
                  history.request_team.team_client_transport && (
                    <div style={{ textAlign: "left" }}>
                      {history.request_team.team_client_transport
                        .map((x) => x.client_transport)
                        .map((item) => (
                          <div key={item.id} style={{ textAlign: "left" }}>
                            <div>
                              {item.truck ? (
                                <>
                                  {" "}
                                  (
                                  <label style={{ color: primaryColor }}>
                                    Truck :{" "}
                                  </label>
                                  <label style={{ color: "#333" }}>
                                    {item.truck.plate_number}
                                  </label>
                                  )
                                </>
                              ) : (
                                <div style={{ color: "red" }}>
                                  Truck{item.id} {"missing plate number"}
                                </div>
                              )}
                              {item.trailer && (
                                <>
                                  {" "}
                                  (
                                  <label style={{ color: primaryColor }}>
                                    Trailer :{" "}
                                  </label>
                                  <label style={{ color: "#333" }}>
                                    {item.trailer.plate_number}
                                  </label>
                                  )
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                <CustomInput
                  id="success"
                  labelText="Date"
                  onChange={(date) => onDataChanged("created_at", date)}
                  value={editedHistory.created_at}
                />
                {history.history_status === REQUEST_HISTORY.STOP && (
                  <div>
                    {history.finished_at && (
                      <CustomInput
                        id="success"
                        labelText="Finish Stop"
                        onChange={(date) => onDataChanged("finished_at", date)}
                        value={editedHistory.finished_at}
                      />
                    )}
                    <div style={{ textAlign: "left" }}>
                      <label style={{ color: primaryColor }}>Duration : </label>
                      <label style={{ color: "#333" }}>
                        {stopDuration(
                          editedHistory.created_at,
                          editedHistory.finished_at
                        )}
                      </label>
                    </div>
                  </div>
                )}
                {history.security_car && (
                  <div style={{ marginTop: 20 }}>
                    <CustomAutoCompleteInput
                      options={cars.cars}
                      filterKey="plate_number"
                      onSelect={(obj) =>
                        obj && onDataChanged("security_car_id", obj)
                      }
                      variant="default"
                      placeholder="search existing cars"
                      labelText="Security Cars"
                      autocompleteValue={{
                        plate_number: editedHistory.security_car.plate_number,
                      }}
                      optionLabel={(option) => {
                        if (option) {
                          let optionLabel = option.plate_number;
                          if (option.brand)
                            optionLabel = `${optionLabel} - ${option.brand}`;
                          if (option.color)
                            optionLabel = `${optionLabel} - ${option.color}`;

                          return optionLabel;
                        }
                      }}
                      id="success"
                      noOptionsText="no cars found linked to this request"
                    />
                  </div>
                )}
                {history.reason && (
                  <CustomInput
                    id="success"
                    labelText="Reason"
                    onChange={(text) => onDataChanged("reason", text)}
                    // helperText={
                    //   this.state.startDateState === "error" &&
                    //   "start date is not valid"
                    // }
                    value={editedHistory.reason}
                    //success={this.state.startDateState === "success"}
                    //error={this.state.startDateState === "error"}
                  />
                )}
                {history.start_kilometer && (
                  <CustomInput
                    id="success"
                    labelText="kilometers"
                    onChange={(text) => onDataChanged("start_kilometer", text)}
                    // helperText={
                    //   this.state.startDateState === "error" &&
                    //   "start date is not valid"
                    // }
                    value={editedHistory.start_kilometer}
                    //success={this.state.startDateState === "success"}
                    //error={this.state.startDateState === "error"}
                  />
                )}
                {history.end_kilometer && (
                  <CustomInput
                    id="success"
                    labelText="End kilometer"
                    onChange={(text) => onDataChanged("end_kilometer", text)}
                    // helperText={
                    //   this.state.startDateState === "error" &&
                    //   "start date is not valid"
                    // }
                    value={editedHistory.end_kilometer}
                    //success={this.state.startDateState === "success"}
                    //error={this.state.startDateState === "error"}
                  />
                )}
                {history.cost && (
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <CustomInput
                      id="success"
                      labelText="Cost"
                      onChange={(text) => onDataChanged("cost", text)}
                      value={editedHistory.cost}
                    />
                    {history.currency && (
                      <div style={{ marginTop: 20 }}>
                        <CustomAutoCompleteInput
                          options={CURRENCIES}
                          onSelect={(obj) =>
                            obj && onDataChanged("currency", obj)
                          }
                          variant="default"
                          placeholder="currency"
                          labelText="Currency"
                          autocompleteValue={{
                            value: editedHistory.currencyObj.value,
                            label: editedHistory.currencyObj.label,
                          }}
                          optionLabel={(option) => {
                            if (option) {
                              let optionLabel = option.label;
                              return optionLabel;
                            }
                          }}
                          id="success"
                          noOptionsText="no currency found"
                        />
                      </div>
                    )}
                  </div>
                )}
                {history.image && (
                  <a
                    href={`${IMAGE_URL}requests/'${request.type}_${request.identifier}/${history.image}`}
                    target="_blank"
                  >
                    {isImage(history.image) ? (
                      <img
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        src={`${IMAGE_URL}requests/'${request.type}_${request.identifier}/${history.image}`}
                      />
                    ) : (
                      <div>
                        <img src={File} width={100} height={100} />
                        <div>{history.image}</div>
                      </div>
                    )}
                  </a>
                )}
                {history.note && (
                  <CustomInput
                    id="success"
                    labelText="Note"
                    multiline
                    // helperText={
                    //   this.state.startDateState === "error" &&
                    //   "start date is not valid"
                    // }
                    onChange={(text) => onDataChanged("note", text)}
                    value={editedHistory.note}
                    //success={this.state.startDateState === "success"}
                    //error={this.state.startDateState === "error"}
                    // inputProps={{
                    //   onChange: props.onChange,
                    //   onFocus: () => openCalendar()
                    // }}
                  />
                )}
                <div style={{ marginTop: 20 }}>
                  <CustomAutoCompleteInput
                    options={agents.agents}
                    filterKey="first_name"
                    onSelect={(obj) => {
                      obj && onDataChanged("agent_id", obj);
                    }}
                    variant="default"
                    placeholder="search existing agents"
                    labelText="Agent"
                    autocompleteValue={{
                      first_name: editedHistory.agent.first_name,
                      last_name: editedHistory.agent.last_name,
                    }}
                    optionLabel={(option) => {
                      if (option && option.first_name && option.last_name) {
                        let optionLabel = `${option.first_name} ${option.last_name}`;
                        if (option.phone_number)
                          optionLabel = `${optionLabel} - ${option.phone_number}`;
                        return optionLabel;
                      }
                      return "";
                    }}
                    id="success"
                    noOptionsText="no agent found linked to this request"
                  />
                </div>
                <div
                  style={{
                    textAlign: "right",
                    color: primaryColor,
                    fontWeight: "bold",
                  }}
                >
                  {/* {moment(history.created_at).format("MMM DD YYYY, HH:mm")} */}
                </div>
                {isLoading[history.id] ? (
                  <CircularProgress style={{ marginTop: -15 }} />
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        const id = history.id;
                        // setEdit({
                        //   ...isEdit,
                        //   [id]: null
                        // });
                        if (editedHistory.created_at) {
                          const start_date = moment(editedHistory.created_at);
                          if (!start_date.isValid()) {
                            alert("the Date is not valid ");
                            return;
                          }
                          editedHistory.created_at = moment(
                            editedHistory.created_at
                          ).format("YYYY-MM-DD HH:mm:ss");
                        }
                        if (editedHistory.finished_at) {
                          const finish_date = moment(editedHistory.finished_at);

                          if (!finish_date.isValid()) {
                            alert("the Date is not valid ");
                            return;
                          }
                          editedHistory.finished_at = moment(
                            editedHistory.finished_at
                          ).format("YYYY-MM-DD HH:mm:ss");
                        }
                        setLoading({
                          ...isLoading,
                          [id]: true,
                        });
                        dispatch(
                          requestsActions.setRequestTimeline(editedHistory)
                        );
                      }}
                      variant="contained"
                      color="primary"
                      className="edit"
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      onClick={setEdit}
                      onClick={() => {
                        const id = history.id;
                        setEdit({
                          ...isEdit,
                          [id]: null,
                        });

                        setEditedHistory(history);
                      }}
                      color="danger"
                      className="edit"
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <div>
                {shiftNumber > 0 && (
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      fontStyle: "italic",
                    }}
                  >
                    Shift {shiftNumber}
                  </div>
                )}
                {history.request_team &&
                  history.request_team.team_client_transport && (
                    <div style={{ textAlign: "left" }}>
                      {history.request_team.team_client_transport
                        .map((x) => x.client_transport)
                        .map((item) => (
                          <div key={item.id} style={{ textAlign: "left" }}>
                            <div>
                              {item.truck ? (
                                <>
                                  {" "}
                                  (
                                  <label style={{ color: primaryColor }}>
                                    Truck :{" "}
                                  </label>
                                  <label style={{ color: "#333" }}>
                                    {item.truck.plate_number}
                                  </label>
                                  )
                                </>
                              ) : (
                                <div style={{ color: "red" }}>
                                  Truck{item.id} {"missing plate number"}
                                </div>
                              )}
                              {item.trailer && (
                                <>
                                  {" "}
                                  (
                                  <label style={{ color: primaryColor }}>
                                    Trailer :{" "}
                                  </label>
                                  <label style={{ color: "#333" }}>
                                    {item.trailer.plate_number}
                                  </label>
                                  )
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                {!isClient && history.security_car && (
                  <div style={{ textAlign: "left" }}>
                    <div>
                      <label style={{ color: primaryColor }}>
                        Security Car :{" "}
                      </label>
                      <label style={{ color: "#333" }}>
                        {history.security_car.plate_number}
                      </label>
                    </div>
                  </div>
                )}
                {history.reason && (
                  <div style={{ textAlign: "left" }}>
                    <label style={{ color: primaryColor }}>Reason : </label>
                    {history.tr_reason &&
                    history.tr_reason != history.reason ? (
                      <label style={{ color: "#333" }}>
                        {history.tr_reason} (original: {history.reason})
                      </label>
                    ) : (
                      <label style={{ color: "#333" }}>{history.reason}</label>
                    )}
                  </div>
                )}
                {history.start_kilometer && (
                  <div style={{ textAlign: "left" }}>
                    <label style={{ color: primaryColor }}>kilometers : </label>
                    <label style={{ color: "#333" }}>
                      {history.start_kilometer}
                    </label>
                  </div>
                )}
                {history.end_kilometer && (
                  <div style={{ textAlign: "left" }}>
                    <label style={{ color: primaryColor }}>
                      End kilometer :{" "}
                    </label>
                    <label style={{ color: "#333" }}>
                      {history.end_kilometer}
                    </label>
                  </div>
                )}
                {history.cost && (
                  <div style={{ textAlign: "left" }}>
                    <label style={{ color: primaryColor }}>Cost : </label>
                    <label style={{ color: "#333" }}>
                      {history.cost} {history.currency}
                    </label>
                  </div>
                )}
                {history.history_status === REQUEST_HISTORY.STOP && (
                  <div>
                    <div style={{ textAlign: "left" }}>
                      <label style={{ color: primaryColor }}>Duration : </label>
                      <label style={{ color: "#333" }}>
                        {stopDuration(history.created_at, history.finished_at)}
                      </label>
                    </div>
                    <div style={{ textAlign: "left" }}>
                      <label style={{ color: primaryColor }}>
                        Finish Stop :{" "}
                      </label>
                      <label style={{ color: "#333" }}>
                        {history.finished_at
                          ? moment(history.finished_at).format(
                              "MMM DD YYYY, HH:mm"
                            )
                          : "In Progress"}
                      </label>
                    </div>
                  </div>
                )}
                {history.image && (
                  <a
                    href={`${IMAGE_URL}requests/'${request.type}_${request.identifier}/${history.image}`}
                    target="_blank"
                  >
                    {isImage(history.image) ? (
                      <img
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "contain",
                        }}
                        src={`${IMAGE_URL}requests/'${request.type}_${request.identifier}/${history.image}`}
                      />
                    ) : (
                      <div>
                        <img src={File} width={100} height={100} />
                        <div>{history.image}</div>
                      </div>
                    )}
                  </a>
                )}
                {history.note && (
                  <div>
                    <FormatQuote />
                    {history.tr_note && history.tr_note != history.note ? (
                      <>
                        <div>original: {history.note}</div>
                        <div style={{ fontSize: 20 }}>{history.tr_note}</div>
                      </>
                    ) : (
                      <div style={{ fontSize: 20 }}>{history.note}</div>
                    )}
                  </div>
                )}
                {!isClient && history.agent && (
                  <div style={{ textAlign: "right", fontWeight: "bold" }}>
                    {history.agent.first_name} {history.agent.last_name}
                  </div>
                )}
                <div
                  style={{
                    textAlign: "right",
                    color: primaryColor,
                    fontWeight: "bold",
                  }}
                >
                  {moment(history.created_at).format("MMM DD YYYY, HH:mm")}
                </div>
                {request.status !== REQUEST_STATUS.ARCHIVED &&
                  isAdmin &&
                  !isOffice && (
                    <Grid style={{ position: "absolute", right: 0, top: 0 }}>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                          const id = history.id;
                          setEdit({
                            ...isEdit,
                            [id]: true,
                          });
                          setEditedHistory(history);
                        }}
                        size="lg"
                        color="primary"
                        className="edit"
                      >
                        <EditIcon />
                      </Button>
                      {(history.history_status === REQUEST_HISTORY.NOTE ||
                        history.history_status === REQUEST_HISTORY.STOP ||
                        history.history_status === REQUEST_HISTORY.STOP_COST ||
                        history.history_status === REQUEST_HISTORY.IMAGE ||
                        i == data.length - 1) &&
                        isAdmin && (
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => onDeleteHistory(history)}
                            size="lg"
                            color="danger"
                            className="edit"
                          >
                            <DeleteIcon />
                          </Button>
                        )}
                    </Grid>
                  )}
              </div>
            )}
          </div>
        ),
      });
    }
    return stories;
  };
  const timeline = generateTimeLine(data, icon, request);

  return (
    <>
      {type === "picture" && (
        <>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Picture Type</FormLabel> */}
            <RadioGroup
              aria-label="gender"
              name="gender1"
              style={{ flexDirection: "row" }}
              value={pictureFilter}
              onChange={(e) => setPictureFilter(e.target.value)}
            >
              <FormControlLabel
                labelPlacement="bottom"
                value="all"
                control={<Radio color="primary" />}
                label="All"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="Truck"
                control={<Radio color="primary" />}
                label="Truck"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="Trailer"
                control={<Radio color="primary" />}
                label="Trailer"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="Seal"
                control={<Radio color="primary" />}
                label="Seal"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="New seal"
                control={<Radio color="primary" />}
                label="New seal"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="Report"
                control={<Radio color="primary" />}
                label="Report"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="CMR"
                control={<Radio color="primary" />}
                label="CMR"
              />
              <FormControlLabel
                labelPlacement="bottom"
                value="Other"
                control={<Radio color="primary" />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          {/* <GridItem xs={12} sm={12} md={12}>
            <Button color="primary">Add picture</Button>
          </GridItem> */}
        </>
      )}

      <Timeline simple stories={timeline} />
      {alertHistory}
      {/* <Lightbox
            style={{ zIndex: 999999 }}
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length
              })
            }
          /> */}
    </>
  );
};

export default RequestNewTimeline;

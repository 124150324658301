export const ERequestsActionTypes = {
  GET_REQUESTS: "GET_REQUESTS",
  GET_REQUESTS_SUCCESS: "GET_REQUESTS_SUCCESS",
  GET_REQUESTS_FAILED: "GET_REQUESTS_FAILED",
  GET_REQUESTS_HISTORY: "GET_REQUESTS_HISTORY",
  GET_REQUESTS_HISTORY_SUCCESS: "GET_REQUESTS_HISTORY_SUCCESS",
  GET_REQUESTS_HISTORY_FAILED: "GET_REQUESTS_HISTORY_FAILED",
  ADD_REQUEST: "ADD_REQUEST",
  SEARCH_REQUEST: "SEARCH_REQUEST",
  SEARCH_REQUEST_SUCCESS: "SEARCH_REQUEST_SUCCESS",
  SEARCH_REQUEST_FAILED: "SEARCH_REQUEST_FAILED",
  CONFIRM_REQUEST: "CONFIRM_REQUEST",
  EDIT_CONFIRM_REQUEST: "EDIT_CONFIRM_REQUEST",
  ADD_OR_EDIT_SHIFT: "ADD_OR_EDIT_SHIFT",
  ADD_OR_EDIT_SHIFT_SUCCESS: "ADD_OR_EDIT_SHIFT_SUCCESS",
  ADD_OR_EDIT_SHIFT_FAILED: "ADD_OR_EDIT_SHIFT_FAILED",
  DELETE_SHIFT: "DELETE_SHIFT",
  EDIT_NOTE: "EDIT_NOTE",
  EDIT_NOTE_SUCCESS: "EDIT_NOTE_SUCCESS",
  EDIT_NOTE_FAILED: "EDIT_NOTE_FAILED",
  DELETE_SHIFT_SUCCESS: "DELETE_SHIFT_SUCCESS",
  DELETE_SHIFT_FAILED: "DELETE_SHIFT_FAILED",
  EDIT_REQUEST: "EDIT_REQUEST",
  EDIT_REQUEST_SUCCESS: "EDIT_REQUEST_SUCCESS",
  EDIT_REQUEST_FAILED: "EDIT_REQUEST_FAILED",
  ADD_TRANSPORT_REQUEST: "ADD_TRANSPORT_REQUEST",
  ADD_TRANSPORT_REQUEST_SUCCESS: "ADD_TRANSPORT_REQUEST_SUCCESS",
  ADD_TRANSPORT_REQUEST_FAILED: "ADD_TRANSPORT_REQUEST_FAILED",
  CANCEL_REQUEST: "CANCEL_REQUEST",
  CANCEL_REQUEST_SUCCESS: "CANCEL_REQUEST_SUCCESS",
  CANCEL_REQUEST_FAILED: "CANCEL_REQUEST_FAILED",
  REJECT_REQUEST: "REJECT_REQUEST",
  REJECT_REQUEST_SUCCESS: "REJECT_REQUEST_SUCCESS",
  REJECT_REQUEST_FAILED: "REJECT_REQUEST_FAILED",
  DELETE_DRIVER: "DELETE_DRIVER",
  ADDING_SUCCESS: "ADDING_SUCCESS",
  ADDING_FAILED: "ADDING_FAILED",
  CONFIRMING_SUCCESS: "CONFIRMING_SUCCESS",
  CONFIRMING_FAILED: "CONFIRMING_FAILED",
  EDIT_CONFIRMING_SUCCESS: "EDIT_CONFIRMING_SUCCESS",
  EDIT_CONFIRMING_FAILED: "EDIT_CONFIRMING_FAILED",
  DELETING_SUCCESS: "DELETING_SUCCESS",
  DELETING_FAILED: "DELETING_FAILED",
  RESET_SUCCESS_SAVE: "RESET_SUCCESS_SAVE",
  RESET_SUCCESS_CANCEL: "RESET_SUCCESS_CANCEL",
  RESET_SUCCESS_REJECT: "RESET_SUCCESS_REJECT",
  RESET_SUCCESS_SEARCH: "RESET_SUCCESS_SEARCH",
  RESET_SUCCESS_EDIT: "RESET_SUCCESS_EDIT",
  SET_VIEW_REQUEST: "SET_VIEW_REQUEST",
  SET_VIEW_REQUEST_SUCCESS: "SET_VIEW_REQUEST_SUCCESS",
  SET_VIEW_REQUEST_FAILED: "SET_VIEW_REQUEST_FAILED",
  SET_REQUEST_HISTORY: "SET_REQUEST_HISTORY",
  SET_REQUEST_HISTORY_SUCCESS: "SET_REQUEST_HISTORY_SUCCESS",
  SET_REQUEST_HISTORY_FAILED: "SET_REQUEST_HISTORY_FAILED",
  DELETE_REQUEST_HISTORY: "DELETE_REQUEST_HISTORY",
  DELETE_REQUEST_HISTORY_SUCCESS: "DELETE_REQUEST_HISTORY_SUCCESS",
  DELETE_REQUEST_HISTORY_FAILED: "DELETE_REQUEST_HISTORY_FAILED",
  ARCHIVE_REQUEST: "ARCHIVE_REQUEST",
  ARCHIVE_REQUEST_SUCCESS: "ARCHIVE_REQUEST_SUCCESS",
  ARCHIVE_REQUEST_FAILED: "ARCHIVE_REQUEST_FAILED",
  RESET_ARCHIVE_SUCCESS: "RESET_ARCHIVE_SUCCESS",
  CHECK_REQUEST: "CHECK_REQUEST",
  CHECK_REQUEST_SUCCESS: "CHECK_REQUEST_SUCCESS",
  CHECK_REQUEST_FAILED: "CHECK_REQUEST_FAILED",
  RESET_CHECK_REQUEST_SUCCESS: "RESET_CHECK_REQUEST_SUCCESS",
  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_REQUEST_SUCCESS: "DELETE_REQUEST_SUCCESS",
  DELETE_REQUEST_FAILED: "DELETE_REQUEST_FAILED",
  RESET_DELETE_CHECK_REQUEST_SUCCESS: "RESET_DELETE_CHECK_REQUEST_SUCCESS",
  EDIT_DATE_REQUEST: "EDIT_DATE_REQUEST",
  EDIT_DATE_REQUEST_SUCCESS: "EDIT_DATE_REQUEST_SUCCESS",
  EDIT_DATE_REQUEST_FAILED: "EDIT_DATE_REQUEST_FAILED",
  RESET_EDIT_DATE_REQUEST_SUCCESS: "RESET_EDIT_DATE_REQUEST_SUCCESS",
  GET_ACTIVE_REQUESTS: "GET_ACTIVE_REQUESTS",
  GET_ACTIVE_REQUESTS_SUCCESS: "GET_ACTIVE_REQUESTS_SUCCESS",
  GET_ACTIVE_REQUESTS_FAILED: "GET_ACTIVE_REQUESTS_FAILED",
  REFRESH_ACTIVE_REQUESTS: "REFRESH_ACTIVE_REQUESTS",
  SEND_ESCORT_REQUESTS_EXCEL: "SEND_ESCORT_REQUESTS_EXCEL",
  SEND_ESCORT_REQUESTS_EXCEL_FAILED: "SEND_ESCORT_REQUESTS_EXCEL_FAILED",
  SEND_ESCORT_REQUESTS_EXCEL_SUCCESS: "SEND_ESCORT_REQUESTS_EXCEL_SUCCESS",
  SEND_STATIC_REQUESTS_EXCEL: "SEND_STATIC_REQUESTS_EXCEL",
  SEND_STATIC_REQUESTS_EXCEL_FAILED: "SEND_STATIC_REQUESTS_EXCEL_FAILED",
  SEND_STATIC_REQUESTS_EXCEL_SUCCESS: "SEND_STATIC_REQUESTS_EXCEL_SUCCESS",
  FILTER_ESCORTS_BY_DATE: "FILTER_ESCORTS_BY_DATE",
  FILTER_ESCORTS_BY_DATE_SUCCESS: "FILTER_ESCORTS_BY_DATE_SUCCESS",
  FILTER_ESCORTS_BY_DATE_FAILED: "FILTER_ESCORTS_BY_DATE_FAILED",
  FILTER_ESCORTS_BY_DATE_INVOICE: "FILTER_ESCORTS_BY_DATE_INVOICE",
  FILTER_ESCORTS_BY_DATE_SUCCESS_INVOICE:
    "FILTER_ESCORTS_BY_DATE_SUCCESS_INVOICE",
  FILTER_ESCORTS_BY_DATE_FAILED_INVOICE:
    "FILTER_ESCORTS_BY_DATE_FAILED_INVOICE",
  EXPORT_ESCORTS: "EXPORT_ESCORTS",
  EXPORT_ESCORTS_SUCCESS: "EXPORT_ESCORTS_SUCCESS",
  EXPORT_ESCORTS_FAILED: "EXPORT_ESCORTS_FAILED",
  EXPORT_ESCORTS_INVOICE: "EXPORT_ESCORTS_INVOICE",
  EXPORT_ESCORTS_SUCCESS_INVOICE: "EXPORT_ESCORTS_SUCCESS_INVOICE",
  EXPORT_ESCORTS_FAILED_INVOICE: "EXPORT_ESCORTS_FAILED_INVOICE",
  UPDATE_REQUEST_NOTES: "UPDATE_REQUEST_NOTES",
  UPDATE_REQUEST_NOTES_SUCCESS: "UPDATE_REQUEST_NOTES_SUCCESS",
  UPDATE_REQUEST_NOTES_FAILED: "UPDATE_REQUEST_NOTES_FAILED",
  FORWARD_REQUEST: "FORWARD_REQUEST",
  FORWARD_SHIFT: "FORWARD_SHIFT",
  SUBC_REQUEST_CHANGE: "SUBC_REQUEST_CHANGE",
  SUBC_REQUEST_CHANGE_SUCCESS: "SUBC_REQUEST_CHANGE_SUCCESS",
  SUBC_REQUEST_CHANGE_FAILED: "SUBC_REQUEST_CHANGE_FAILED",
  FORWARD_REQUEST_SUCCESS: "FORWARD_REQUEST_SUCCESS",
  CANCEL_FORWARD_SUCCESS: "CANCEL_FORWARD_SUCCESS",
  FORWARD_REQUEST_FAILED: "FORWARD_REQUEST_FAILED",
  FORWARD_SHIFT_SUCCESS: "FORWARD_SHIFT_SUCCESS",
  FORWARD_SHIFT_FAILED: "FORWARD_SHIFT_FAILED",
  FORWARD_REQUEST_RESET: "FORWARD_REQUEST_RESET",
  GET_COORDS_HISTORY: "GET_COORDS_HISTORY",
  GET_COORDS_HISTORY_SUCCESS: "GET_COORDS_HISTORY_SUCCESS",
  GET_COORDS_HISTORY_FAILED: "GET_COORDS_HISTORY_FAILED",
  GET_TEAM_HISTORY: "GET_TEAM_HISTORY",
  GET_TEAM_HISTORY_SUCCESS: "GET_TEAM_HISTORY_SUCCESS",
  GET_TEAM_HISTORY_FAILED: "GET_TEAM_HISTORY_FAILED",
  GET_REQUESTS_MEMBERS: "GET_REQUESTS_MEMBERS",
  GET_REQUESTS_MEMBERS_SUCCESS: "GET_REQUESTS_MEMBERS_SUCCESS",
  GET_REQUESTS_MEMBERS_FAILED: "GET_REQUESTS_MEMBERS_FAILED",
  GET_REQUEST_DETAILS: "GET_REQUEST_DETAILS",
  GET_REQUEST_DETAILS_SUCCESS: "GET_REQUEST_DETAILS_SUCCESS",
  GET_REQUEST_DETAILS_FAILED: "GET_REQUEST_DETAILS_FAILED",
  GET_REQUEST_IMAGES: "GET_REQUEST_IMAGES",
  GET_REQUEST_IMAGES_SUCCESS: "GET_REQUEST_IMAGES_SUCCESS",
  GET_REQUEST_IMAGES_FAILED: "GET_REQUEST_IMAGES_FAILED",
  GET_REQUEST_BY_ID: "GET_REQUEST_BY_ID",
  GET_REQUEST_BY_ID_SUCCESS: "GET_REQUEST_BY_ID_SUCCESS",
  GET_REQUEST_BY_ID_FAILED: "GET_REQUEST_BY_ID_FAILED",
  GET_ACTIVE_REQUEST_BY_ID: "GET_ACTIVE_REQUEST_BY_ID",
  GET_ACTIVE_REQUEST_BY_ID_SUCCESS: "GET_ACTIVE_REQUEST_BY_ID_SUCCESS",
  GET_ACTIVE_REQUEST_BY_ID_FAILED: "GET_ACTIVE_REQUEST_BY_ID_FAILED",
  UPDATE_TEAM_LOCATION: "UPDATE_TEAM_LOCATION",
  FORCE_MAP_UPDATE: "FORCE_MAP_UPDATE",
};

export const requestsActions = {
  fetchRequests: (payload) => ({
    type: ERequestsActionTypes.GET_REQUESTS,
    payload,
  }),
  addRequests: (payload) => ({
    type: ERequestsActionTypes.ADD_REQUEST,
    payload,
  }),
  searchRequest: (payload) => ({
    type: ERequestsActionTypes.SEARCH_REQUEST,
    payload,
  }),
  getRequestById: (requestId, actionType) => ({
    type: ERequestsActionTypes.GET_REQUEST_BY_ID,
    payload: { requestId, actionType },
  }),
  getActiveRequestById: (requestId, actionType) => ({
    type: ERequestsActionTypes.GET_ACTIVE_REQUEST_BY_ID,
    payload: { requestId, actionType },
  }),
  updateTeamLocation: (payload) => ({
    type: ERequestsActionTypes.UPDATE_TEAM_LOCATION,
    payload,
  }),
  searchRequestSuccess: (payload) => ({
    type: ERequestsActionTypes.SEARCH_REQUEST_SUCCESS,
    payload,
  }),
  confirmRequest: (payload, id, is_new) => ({
    type: ERequestsActionTypes.CONFIRM_REQUEST,
    payload: { body: payload, id, is_new },
  }),
  editConfirmRequest: (payload, id, is_new) => ({
    type: ERequestsActionTypes.EDIT_CONFIRM_REQUEST,
    payload: { body: payload, id, is_new },
  }),
  addOrEditShift: (payload, id, is_new) => ({
    type: ERequestsActionTypes.ADD_OR_EDIT_SHIFT,
    payload: { body: payload, id, is_new },
  }),
  deleteShift: (shiftId, requestId, is_new) => ({
    type: ERequestsActionTypes.DELETE_SHIFT,
    payload: { shiftId, requestId, is_new },
  }),
  editNote: (note, id) => ({
    type: ERequestsActionTypes.EDIT_NOTE,
    payload: { note, id },
  }),
  rejectRequest: (payload, id) => ({
    type: ERequestsActionTypes.REJECT_REQUEST,
    payload: { body: payload, id },
  }),
  editTransportsRequest: (payload) => ({
    type: ERequestsActionTypes.EDIT_REQUEST,
    payload,
  }),
  addTransportsRequest: (payload) => ({
    type: ERequestsActionTypes.ADD_TRANSPORT_REQUEST,
    payload,
  }),
  resetSuccessEditTransport: (payload) => ({
    type: ERequestsActionTypes.RESET_SUCCESS_EDIT,
    payload,
  }),
  cancelRequest: (payload) => ({
    type: ERequestsActionTypes.CANCEL_REQUEST,
    payload,
  }),
  resetSuccessSave: () => ({
    type: ERequestsActionTypes.RESET_SUCCESS_SAVE,
  }),
  resetSuccessCancel: () => ({
    type: ERequestsActionTypes.RESET_SUCCESS_CANCEL,
  }),
  resetSuccessReject: () => ({
    type: ERequestsActionTypes.RESET_SUCCESS_REJECT,
  }),
  resetSuccessDelete: () => ({
    type: ERequestsActionTypes.DELETE_REQUEST,
  }),
  resetSuccessArchive: () => ({
    type: ERequestsActionTypes.RESET_ARCHIVE_SUCCESS,
  }),
  resetSuccessSearch: () => ({
    type: ERequestsActionTypes.RESET_SUCCESS_SEARCH,
  }),
  getRequestHistory: (payload) => ({
    type: ERequestsActionTypes.GET_REQUESTS_HISTORY,
    payload,
  }),
  setViewRequest: (payload) => ({
    type: ERequestsActionTypes.SET_VIEW_REQUEST,
    payload,
  }),
  setRequestTimeline: (payload) => ({
    type: ERequestsActionTypes.SET_REQUEST_HISTORY,
    payload,
  }),
  archiveRequest: (payload) => ({
    type: ERequestsActionTypes.ARCHIVE_REQUEST,
    payload,
  }),
  resetSuccessCheck: () => ({
    type: ERequestsActionTypes.RESET_CHECK_REQUEST_SUCCESS,
  }),
  checkRequest: (payload) => ({
    type: ERequestsActionTypes.CHECK_REQUEST,
    payload,
  }),
  deleteRequest: (payload) => ({
    type: ERequestsActionTypes.DELETE_REQUEST,
    payload,
  }),
  deleteRequestHistory: (payload) => ({
    type: ERequestsActionTypes.DELETE_REQUEST_HISTORY,
    payload,
  }),
  editDateRequest: (payload) => ({
    type: ERequestsActionTypes.EDIT_DATE_REQUEST,
    payload,
  }),
  resetEditDateRequestSuccess: (payload) => ({
    type: ERequestsActionTypes.RESET_EDIT_DATE_REQUEST_SUCCESS,
    payload,
  }),
  getActiveRequestsSuccess: (payload) => ({
    type: ERequestsActionTypes.GET_ACTIVE_REQUESTS_SUCCESS,
    payload,
  }),
  getActiveRequests: (payload) => ({
    type: ERequestsActionTypes.GET_ACTIVE_REQUESTS,
    payload,
  }),
  refreshActiveRequests: (payload) => ({
    type: ERequestsActionTypes.REFRESH_ACTIVE_REQUESTS,
    payload,
  }),
  sendEscortRequestsExcel: (payload) => ({
    type: ERequestsActionTypes.SEND_ESCORT_REQUESTS_EXCEL,
    payload,
  }),
  sendStaticRequestsExcel: (payload) => ({
    type: ERequestsActionTypes.SEND_STATIC_REQUESTS_EXCEL,
    payload,
  }),
  filterEscortByDate: (payload) => ({
    type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE,
    payload,
  }),
  filterEscortInvoiceByDate: (payload) => ({
    type: ERequestsActionTypes.FILTER_ESCORTS_BY_DATE_INVOICE,
    payload,
  }),
  exportEscorts: (payload) => ({
    type: ERequestsActionTypes.EXPORT_ESCORTS,
    payload,
  }),
  exportEscortsInvoice: (payload) => ({
    type: ERequestsActionTypes.EXPORT_ESCORTS_INVOICE,
    payload,
  }),
  updateRequestNotes: (payload) => ({
    type: ERequestsActionTypes.UPDATE_REQUEST_NOTES,
    payload,
  }),
  forwardRequest: (payload) => ({
    type: ERequestsActionTypes.FORWARD_REQUEST,
    payload,
  }),
  forwardShift: (payload) => ({
    type: ERequestsActionTypes.FORWARD_SHIFT,
    payload,
  }),
  onSubcontractorRequestChange: (payload) => ({
    type: ERequestsActionTypes.SUBC_REQUEST_CHANGE,
    payload,
  }),
  resetSuccessForward: () => ({
    type: ERequestsActionTypes.FORWARD_REQUEST_RESET,
  }),
  getCoordsHistory: (payload) => ({
    type: ERequestsActionTypes.GET_COORDS_HISTORY,
    payload,
  }),
  clearCoords: () => ({
    type: ERequestsActionTypes.GET_COORDS_HISTORY_SUCCESS,
    payload: [],
  }),
  getTeamHistory: (payload) => ({
    type: ERequestsActionTypes.GET_TEAM_HISTORY,
    payload,
  }),
  clearTeamHistory: () => ({
    type: ERequestsActionTypes.GET_TEAM_HISTORY_SUCCESS,
    payload: [],
  }),
  getRequestsMembers: (payload) => ({
    type: ERequestsActionTypes.GET_REQUESTS_MEMBERS,
    payload,
  }),
  getRequestDetails: (payload) => ({
    type: ERequestsActionTypes.GET_REQUEST_DETAILS,
    payload,
  }),
  getChannelImages: (payload) => ({
    type: ERequestsActionTypes.GET_REQUEST_IMAGES,
    payload,
  }),
  forceMapUpdate: () => ({
    type: ERequestsActionTypes.FORCE_MAP_UPDATE,
  }),
};

import moment from "moment";
import XLSX from "xlsx";
/* generate an array of column objects */
export const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  return o;
};

export const SheetJSFT = [
  "xlsx",
  "xlsb",
  "xlsm",
  "xls",
  "xml",
  "csv",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm",
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");

export const prepareEscortRequest = (data, user, drivers, trucks, trailers) => {
  let pup;
  let requestList = [];
  let request = {};
  for (let i = 1; i < data.length; i++) {
    if (data[i].length > 0) {
      const identifier = data[i][0];
      pup = data[i][1];
      pup = !pup && i > 1 ? request.pup : pup;
      const dop = data[i][2];
      const loading_day = data[i][3];
      const loading_time = data[i][4];
      const depart_time = data[i][5];
      const deliver_day = data[i][6];
      const deliver_time = data[i][7];
      const truck_plate_number = data[i][8] || "";
      const trailer_plate_number = data[i][9] || "";
      const driver_1_name = data[i][10] || "";
      const driver_2_name = data[i][11] || "";

      const truck = trucks.find(
        (x) => x.plate_number === truck_plate_number.trim().toUpperCase()
      );
      const trailer = trailers.find(
        (x) => x.plate_number === trailer_plate_number.trim().toUpperCase()
      );

      const driver1 = drivers.find(
        (x) =>
          x.first_name.toUpperCase() + " " + x.last_name.toUpperCase() ==
          driver_1_name.trim().toUpperCase()
      );
      const driver2 = drivers.find(
        (x) =>
          x.first_name.toUpperCase() + " " + x.last_name.toUpperCase() ==
          driver_2_name.trim().toUpperCase()
      );
      const start_date =
        moment(`${loading_day}`).format("YYYY-MM-DD") + " " + loading_time;

      let clientTransport = {};
      if (truck_plate_number) {
        clientTransport.truck = {
          plate_number: truck ? truck.plate_number : truck_plate_number,
          id: truck ? truck.id : null,
        };
      } else {
        clientTransport.truck = {
          plate_number: "",
        };
      }
      if (driver_1_name) {
        clientTransport.driver1 = {
          first_name: driver1
            ? driver1.first_name
            : driver_1_name.split(" ")[0],
          last_name: driver1
            ? driver1.last_name
            : driver_1_name.replace(driver_1_name.split(" ")[0], "").trim(),
          id: driver1 ? driver1.id : null,
          phone_number: driver1 ? driver1.phone_number : null,
        };
      } else {
        clientTransport.driver1 = {
          first_name: "",
          last_name: "",
        };
      }
      if (trailer_plate_number) {
        clientTransport.trailer = {
          plate_number: trailer ? trailer.plate_number : trailer_plate_number,
          id: trailer ? trailer.id : null,
        };
      }
      if (driver_2_name) {
        clientTransport.driver2 = {
          first_name: driver2
            ? driver2.first_name
            : driver_2_name.split(" ")[0],
          last_name: driver2
            ? driver2.last_name
            : driver_2_name.replace(driver_2_name.split(" ")[0], "").trim(),
          id: driver2 ? driver2.id : null,
          phone_number: driver2 ? driver2.phone_number : null,
        };
      }
      request = {
        identifier,
        branch_id: user.branch_id,
        client_id: user.client_id,
        created_at: moment().format("YYYY-MM-DD H:mm:ss"),
        original_start_date: start_date,
        is_excel: 1,
        client: user.branch.client.name + "-" + user.branch.city,
        type: "Escort",
        start_date,
        depart_time,
        deliver_day,
        deliver_time,
        take_over_start_date: null,
        pup: pup || request.pup,
        status: 0,
        escort_number: 1,
        dop: dop,
        client_transport: clientTransport,
      };
      requestList.push(request);
    }
  }
  return requestList;
};
export const prepareStaticRequest = (data, user) => {
  let requestList = [];
  let request = {};
  for (let i = 1; i < data.length; i++) {
    if (data[i].length > 0) {
      const identifier = data[i][0];
      const pup = data[i][1];
      const start_date_label = data[i][2];
      const start_time = data[i][3];
      const end_date_label = data[i][4];
      const end_time = data[i][5];
      const agent_number = data[i][6];
      const note = data[i][7];

      const start_date =
        moment(`${start_date_label}`).format("YYYY-MM-DD") + " " + start_time;
      const end_date =
        moment(`${end_date_label}`).format("YYYY-MM-DD") + " " + end_time;

      request = {
        identifier,
        branch_id: user.branch_id,
        client_id: user.client_id,
        created_at: moment().format("YYYY-MM-DD H:mm:ss"),
        original_start_date: start_date,
        end_date: end_date,
        is_excel: 1,
        client: user.branch.client.name + "-" + user.branch.city,
        type: "Static",
        start_date,
        pup: pup,
        status: 0,
        number_agents: agent_number,
        instructions: note,
      };
      requestList.push(request);
    }
  }
  return requestList;
};

import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import NativeButton from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import GridItem from "components/Grid/GridItem";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { IMAGE_URL } from "../../../src/constants";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewClientDialog = (props) => {
  const classes = useStyles(styles);
  return (
    <Dialog
      disableEnforceFocus={true}
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{props.title}</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <CustomInput
          id="success"
          style={{ marginTop: 20 }}
          value={props.data.name}
          onChange={(text) => props.onDataChanged("name", text)}
          labelText="Name"
          helperText={props.nameState === "error" && "client name is required"}
          success={props.nameState === "success"}
          error={props.nameState === "error"}
          formControlProps={{
            fullWidth: true,
          }}
        />
        <GridItem style={{ marginTop: 20 }}>
          {/* <NativeButton
            style={{ backgroundColor: "transparent", width: 150, height: 150  }}
            color="primary"
            variant="outlined">
            <InsertPhotoIcon fontSize="large"/>
            Upload logo
          </NativeButton> */}
          <ImageUpload
            logo={props.data.logo ? IMAGE_URL + props.data.logo : null}
            onDataChanged={props.onDataChanged}
          />
        </GridItem>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          onClick={props.onClose}
          variant="contained"
          color="primary"
          simple
        >
          Close
        </Button>
        {props.saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button onClick={props.onSubmit} color="primary" variant="contained">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default NewClientDialog;

NewClientDialog.prototype = {
  isModalVisible: PropTypes.bool,
};

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavPills from "components/NavPills/NavPills";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
  REQUEST_STATUS,
  isSubContractor,
  isAdmin,
  REQUEST_TYPE,
} from "../../../../src/constants";
import RequestClientDetails from "./RequetClientDetails";
import RequestSecurityDetails from "./RequestSecurityDetails";
import RequestHistory from "./RequestHistory";
import { requestsActions } from "redux/requests/actions";
import RequestArchive from "./RequestArchive";
import RequestNewHistory from "./RequestNewHistory";
import { getUserOffice } from "redux/requests/selectors";
import RequestNote from "./RequestNote";

const RequestItemDetails = (props) => {
  const dispatch = useDispatch();
  const isRoleOffice = useSelector(getUserOffice);
  const isOffice = false;
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (props.tracking) {
      dispatch(requestsActions.getRequestHistory(props.request.id));
    }
  }, []);
  let tabs = [
    {
      tabButton: "Client Details",
      tabContent: (
        <RequestClientDetails
          isOffice={isRoleOffice}
          onRejectRequest={props.onRejectRequest}
          {...props}
        />
      ),
    },
  ];

  if (props.request.status === REQUEST_STATUS.PENDING) {
    if (
      isSubContractor ||
      isAdmin
      // && props.request.status === REQUEST_STATUS.PENDING
    ) {
      tabs.push({
        tabButton:
          props.request.type != REQUEST_TYPE.MONITORING
            ? "Confirm Security Details"
            : "Confirm Monitoring",
        tabContent: (
          <RequestSecurityDetails
            isOffice={isOffice}
            onSubcontractorRequestChange={props.onSubcontractorRequestChange}
            onConfirmRequest={props.onConfirmRequest}
            onConfirmOldRequest={props.onConfirmOldRequest}
            {...props}
          />
        ),
      });
    }
  } else {
    tabs.push({
      tabButton: "Notes",
      tabContent: <RequestNote isOffice={isOffice} {...props} />,
    });
  }
  if (
    (props.request.status === REQUEST_STATUS.ACCEPTED ||
      props.request.status === REQUEST_STATUS.FINISHED ||
      props.request.status === REQUEST_STATUS.CHECKED ||
      props.request.status === REQUEST_STATUS.ARCHIVED) &&
    props.request.type != REQUEST_TYPE.MONITORING
  ) {
    tabs.push({
      tabButton: "History",
      tabContent:
        props.request.is_new == 1 ? (
          <RequestNewHistory isOffice={isOffice} {...props} />
        ) : (
          <RequestHistory isOffice={isOffice} {...props} />
        ),
    });
  }
  if (
    !props.isOffice &&
    !isSubContractor &&
    (props.request.status === REQUEST_STATUS.FINISHED ||
      props.request.status === REQUEST_STATUS.CHECKED ||
      props.request.status === REQUEST_STATUS.ARCHIVED ||
      props.request.status === REQUEST_STATUS.CANCELED)
  ) {
    tabs.push({
      tabButton:
        props.request.status === REQUEST_STATUS.ARCHIVED ||
        props.request.status === REQUEST_STATUS.CHECKED ||
        props.request.status === REQUEST_STATUS.CANCELED
          ? "Archive"
          : "Client Check",
      tabContent: (
        <RequestArchive
          isOffice={isOffice}
          onCheckRequest={props.onCheckRequest}
          onArchiveRequest={props.onArchiveRequest}
          {...props}
        />
      ),
    });
  }

  if (props.tracking) {
    tabs = [
      {
        tabButton: "History",
        tabContent:
          props.request.is_new == 1 ? (
            <RequestNewHistory isOffice={isOffice} {...props} />
          ) : (
            <RequestHistory isOffice={isOffice} {...props} />
          ),
      },
    ];
  }

  const onTabChange = (index) => {
    if (props.tracking) {
      dispatch(requestsActions.getRequestHistory(props.request.id));
    } else {
      setSelectedTab(index);
      if (index == 2) {
        dispatch(requestsActions.getRequestHistory(props.request.id));
      }
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
        <NavPills
          onTabChange={onTabChange}
          color="primary"
          active={selectedTab}
          tabs={tabs}
        />
      </GridItem>
    </GridContainer>
  );
};

export default RequestItemDetails;

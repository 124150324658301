import React, { useState } from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions, Grid, Radio } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import GridContainer from "components/Grid/GridContainer";
import { primaryColor } from "../../../constants";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SubcontractorListDialog = (props) => {
  const classes = useStyles(styles);
  const { list } = useSelector((state) => state.subcontractors);
  const { forwarding } = useSelector((state) => state.requests);
  const [selectedSub, setSelectedSub] = useState({});
  return (
    <Dialog
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      disableEnforceFocus={true}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{props.title}</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer style={{ maxHeight: "60vh", overflow: "scroll" }}>
          {list.map((item) => {
            return (
              <Grid
                onClick={() => setSelectedSub(item)}
                className="request_card"
                xs={4}
                sm={4}
                md={4}
                key={item.id}
                style={{
                  flexDirection: "row",
                  float: "left",
                  border: "1px solid #607D8B",
                }}
              >
                <Radio
                  checked={selectedSub.id == item.id}
                  color="primary"
                  onChange={() => setSelectedSub(item)}
                />
                <Grid>
                  <div style={{ fontWeight: "bold", color: primaryColor }}>
                    {item.company_name}
                  </div>
                  <div style={{ fontWeight: "bold" }}>
                    {item.contact_person}
                  </div>
                  {/* <div>{item.company_address}</div> */}
                  <div>
                    {item.phone_number}
                    {item.emergency_phone_number
                      ? " / emergency :" + item.emergency_phone_number
                      : ""}
                  </div>
                  <div>{item.email}</div>
                </Grid>
              </Grid>
            );
          })}
        </GridContainer>
      </DialogContent>
      <DialogActions
        // style={{ alignItems: "center", justifyContent: "center" }}
        className={classes.modalFooter}
      >
        <div style={{ flex: 1 }}>
          {forwarding ? (
            <CircularProgress
              size={30}
              color="primary"
              style={{ marginRight: 30 }}
            />
          ) : (
            <Button
              disabled={!selectedSub.id}
              onClick={() => props.onSubmit(selectedSub)}
              color="primary"
              variant="contained"
              fullWidth
            >
              Save
            </Button>
          )}
          <Button
            onClick={props.onClose}
            variant="contained"
            color="primary"
            simple
            fullWidth
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
export default SubcontractorListDialog;

SubcontractorListDialog.prototype = {
  isModalVisible: PropTypes.bool,
};

import React from "react";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import { InputAdornment, DialogActions } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { COUNTRIES } from "../../constants";

const useStyles = makeStyles({
  ...styles,
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NewSubcontractorDialog = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      disableEnforceFocus={true}
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={props.isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={props.onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>{props.title}</h3>
      </DialogTitle>
      <ValidatorForm onSubmit={props.onSubmit}>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.company_name}
            onChange={(e) =>
              props.onDataChanged("company_name", e.target.value)
            }
            label="Company name"
            validators={["required"]}
            errorMessages={["name is required"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.company_address}
            onChange={(e) =>
              props.onDataChanged("company_address", e.target.value)
            }
            label="Company address"
            validators={["required"]}
            errorMessages={["Address is required"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.contact_person}
            onChange={(e) =>
              props.onDataChanged("contact_person", e.target.value)
            }
            label="Contact person"
            validators={["required"]}
            errorMessages={["Contact person is required"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.email}
            onChange={(e) => props.onDataChanged("email", e.target.value)}
            label="Company email"
            validators={["required", "isEmail"]}
            errorMessages={["Email is required", "Email is not valid"]}
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.phone_number}
            onChange={(e) =>
              props.onDataChanged("phone_number", e.target.value)
            }
            label="Phone number"
            fullWidth
          />
          <TextValidator
            style={{ marginTop: 20 }}
            value={props.data.emergency_phone_number}
            onChange={(e) =>
              props.onDataChanged("emergency_phone_number", e.target.value)
            }
            label="Emergency Phone number"
            fullWidth
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={props.onClose}
            variant="contained"
            color="primary"
            simple
          >
            Close
          </Button>
          {props.saving ? (
            <CircularProgress
              size={30}
              color="primary"
              style={{ marginRight: 30 }}
            />
          ) : (
            <Button type="submit" color="primary" variant="contained">
              Save
            </Button>
          )}
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};
export default NewSubcontractorDialog;

NewSubcontractorDialog.prototype = {
  isModalVisible: PropTypes.bool,
};

import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { subcontractorsActions } from "redux/subcontractors/actions";
import NewSubcontractorDialog from "./NewSubcontractorDialog";
import { history } from "../../redux/store";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
    : true;
}

export const SubcontractorManagement = () => {
  const dispatch = useDispatch();
  const [isNewClientVisible, setNewClientVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [client, setClient] = useState({});
  const [nameState, setNameState] = useState("");
  const { list, saving, savingSuccess, deleteSuccess } = useSelector(
    (state) => state.subcontractors
  );
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(subcontractorsActions.fetchSubcontractors());
  }, []);

  useEffect(() => {
    if (savingSuccess) {
      setNewClientVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          title={`Subcontractor ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Subcontractor has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);
  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          title="Deleted!"
          onConfirm={onCancel}
          onCancel={onCancel}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Subcontractor has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onCancel = () => {
    setAlert(null);
    dispatch(subcontractorsActions.resetSuccessDelete());
  };

  const onDismissSave = () => {
    setAlert(null);
    dispatch(subcontractorsActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = (obj) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the subcontractor.
      </SweetAlert>
    );
  };

  const onDelete = (obj) => {
    dispatch(subcontractorsActions.deleteSubcontractor(obj.id));
  };

  const onDataChanged = (key, value) => {
    setClient({
      ...client,
      [key]: value,
    });
  };

  const onSubmit = () => {
    dispatch(subcontractorsActions.saveSubcontractor(client));
  };

  const onOpenDialog = () => {
    setClient({
      company_name: "",
      company_address: "",
      email: "",
      phone_number: "",
      contact_person: "",
      emergency_phone_number: "",
    });
    setEdit(false);
    setNameState("");
    setNewClientVisible(true);
  };

  const onEdit = (client) => {
    setEdit(true);
    setClient(client);
    setNameState("success");
    setNewClientVisible(true);
  };

  const classes = useStyles();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Subcontractor
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <BusinessCenterIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Subcontractors Management ({list.length})
            </h4>
          </CardHeader>
          <CardBody />
        </Card>
        <GridContainer>
          {!!list &&
            list.map((client) => (
              <GridItem key={client.id} xs={6} sm={6} md={6}>
                <Card style={{ float: "right", cursor: "pointer" }}>
                  <Box
                    display="flex"
                    className="actions-right"
                    style={{
                      // justifyContent: "flex-end",
                      float: "right",
                      width: "100%",
                      marginRight: 10,
                      justifyContent: "flex-between",
                      flexDirection: "row",
                    }}
                  >
                    <Box flexGrow={1} />
                    <Box>
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => onEdit(client)}
                        size="lg"
                        color="primary"
                        className="edit"
                      >
                        <EditIcon />
                      </Button>{" "}
                      {/* use this button to remove the data row */}
                      <Button
                        justIcon
                        round
                        simple
                        onClick={() => warningWithConfirmMessage(client)}
                        size="lg"
                        color="danger"
                        className="remove"
                      >
                        <DeleteForeverIcon />
                      </Button>{" "}
                    </Box>
                  </Box>
                  <Grid
                    onClick={() =>
                      history.push(`/admin/subc-accounts/${client.id}`)
                    }
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="primary"
                      style={{ marginBottom: 10 }}
                    >
                      {client.company_name}
                    </Typography>
                    {client.contact_person && (
                      <div style={{ flexDirection: "row" }}>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            fontSize: 16,
                          }}
                        >
                          Contact person :{" "}
                        </label>
                        <label style={{ color: "#000", fontSize: 16 }}>
                          {client.contact_person}
                        </label>
                      </div>
                    )}

                    {client.phone_number && (
                      <div style={{ flexDirection: "row" }}>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            fontSize: 16,
                          }}
                        >
                          Phone number :{" "}
                        </label>
                        <label style={{ color: "#000", fontSize: 16 }}>
                          {client.phone_number}
                        </label>
                      </div>
                    )}
                    {client.email && (
                      <div style={{ flexDirection: "row" }}>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            fontSize: 16,
                          }}
                        >
                          Company email :{" "}
                        </label>
                        <label style={{ color: "#000", fontSize: 16 }}>
                          {client.email}
                        </label>
                      </div>
                    )}
                    {client.emergency_phone_number && (
                      <div style={{ flexDirection: "row" }}>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            fontSize: 16,
                          }}
                        >
                          Emergency Phone number :{" "}
                        </label>
                        <label style={{ color: "#000", fontSize: 16 }}>
                          {client.emergency_phone_number}
                        </label>
                      </div>
                    )}
                    {client.company_address && (
                      <div style={{ flexDirection: "row" }}>
                        <label
                          style={{
                            fontWeight: "bold",
                            color: "#000",
                            fontSize: 16,
                          }}
                        >
                          Company address :{" "}
                        </label>
                        <label style={{ color: "#000", fontSize: 16 }}>
                          {client.company_address}
                        </label>
                      </div>
                    )}
                  </Grid>
                </Card>
              </GridItem>
            ))}
        </GridContainer>
      </GridItem>
      {isNewClientVisible && (
        <NewSubcontractorDialog
          title={client.id ? "Edit Subcontractor" : "Add new Subcontractor"}
          onClose={() => setNewClientVisible(false)}
          onSubmit={onSubmit}
          onDataChanged={onDataChanged}
          data={client}
          nameState={nameState}
          isModalVisible={isNewClientVisible}
          saving={saving}
        />
      )}
      {alert}
    </GridContainer>
  );
};

export default SubcontractorManagement;

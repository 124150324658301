/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import React, { createRef } from "react";
import ReactDOM from "react-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store, { history } from "./redux/store";
import { ConnectedRouter } from "connected-react-router";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import SplashScreen from "SplashScreen";
import ChatWidget from "views/Admin/chat/ChatWidget";
import { isClient } from "../src/constants";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUG_SNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      popup: { zIndex: 1300 },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#607D8B",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#607D8B",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#607D8B",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#607D8B",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
    MuiInputBase: {
      //input: {
      "&Mui-disabled": {
        color: "red",
      },
      //}
    },
    MuiTypography: {
      typography: {
        color: "#607D8B",
      },
    },
    MuiListItemText: {},
    MuiTab: {
      wrapper: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#607D8B",
      },
    },
  },
  palette: {
    primary: {
      main: "#607D8B",
    },
  },
  typography: {
    color: "#000",
  },
});
const role = process.env.REACT_APP_ROLE;

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
export const audioRef = createRef();
export const audioNotifRef = createRef();
export const audioAlarmRef = createRef();

ReactDOM.render(
  <ErrorBoundary>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <ConnectedRouter basename="protectrans" history={history}>
          <ReactNotification />
          <Switch>
            {/* <Route path="/rtl" component={RtlLayout} /> */}
            <Route path="/auth" component={AuthLayout} />
            <Route path={`/${role}`} component={AdminLayout} />
            <Route path="/refreshing" component={SplashScreen} />
            <Redirect from="/" to="/auth/login-page" />
          </Switch>
          {<ChatWidget />}
          <audio
            ref={audioRef}
            src={require("assets/mp3/new_message.mp3")}
            style={{ display: "none" }}
          />
          <audio
            ref={audioNotifRef}
            src={require("assets/mp3/request.mp3")}
            style={{ display: "none" }}
          />
          <audio
            ref={audioAlarmRef}
            src={require("assets/mp3/alarm.mp3")}
            style={{ display: "none" }}
          />
        </ConnectedRouter>
      </Provider>
    </MuiThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

import { Fab } from "@material-ui/core";
import { ChatBubble, CloseRounded } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ChatGroup from "./ChatGroup";
import Collapse from "@material-ui/core/Collapse";
import { useDispatch, useSelector } from "react-redux";
import PusherController from "views/PusherController";
import { getAllUnreadMessagesCount } from "redux/chat/selectors";
import { chatActions } from "redux/chat/actions";
import { isMobile } from "react-device-detect";
import { isClient } from "../../../constants";
const style = {
  margin: 0,
  top: "auto",
  right: isMobile ? 20 : 50,
  bottom: isMobile ? 20 : 50,
  left: "auto",
  position: "fixed",
  zIndex: 99999,
};

export const whitelistClients = [11, 14];

const ChatWidget = () => {
  const dispatch = useDispatch();
  const { user, isUserLoggedIn } = useSelector((state) => state.user);
  const { currentChannel } = useSelector((state) => state.chat);
  const [isVisible, setVisible] = useState(false);
  const unreadMessageCount = useSelector(getAllUnreadMessagesCount);

  useEffect(() => {
    dispatch(chatActions.toggleChatWidget(isVisible));
    if (isVisible && !!currentChannel)
      dispatch(chatActions.setReadAllMessages(currentChannel.channel_id));
  }, [isVisible]);
  useEffect(() => {
    document.title = `Protectrans ${
      unreadMessageCount > 0 && isUserLoggedIn ? `(${unreadMessageCount})` : ""
    }`;
  }, [unreadMessageCount, isUserLoggedIn]);

  if (!!user && user.id) {
    if (
      isClient &&
      !whitelistClients.includes(parseInt(parseInt(user.client_id)))
    )
      return null;
    return (
      <div style={style}>
        <Collapse in={isVisible}>
          <ChatGroup />
        </Collapse>

        <div
          style={{
            alignItems: "flex-end",
            position: "absolute",
            right: 0,
            bottom: 10,
          }}
        >
          <Fab
            onClick={() => setVisible(!isVisible)}
            color="primary"
            aria-label="add"
          >
            {isVisible ? <CloseRounded /> : <ChatBubble />}
          </Fab>

          {!!unreadMessageCount && unreadMessageCount > 0 && (
            <div
              style={{
                minWidth: 20,
                minHeight: 20,
                borderRadius: 10,
                backgroundColor: "#dc004e",
                position: "absolute",
                right: -3,
                top: -3,
              }}
            >
              <div
                style={{
                  color: "#fff",
                  margin: "auto",
                  padding: 2,
                  textAlign: "center",
                }}
              >
                {unreadMessageCount}
              </div>
            </div>
          )}
        </div>
        <PusherController />
      </div>
    );
  }
  return null;
};

export default ChatWidget;

import React from "react";
import { Grid, Box } from "@material-ui/core";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import moment from "moment";
import Button from "components/CustomButtons/Button";
import EditIcon from "@material-ui/icons/Edit";

const RequestDateItem = (props) => {
  const fromDate = moment(props.fromDate);
  const toDate = moment(props.toDate);
  return (
    <Grid>
      <Box display="flex">
        <TodayIcon style={{ marginTop: 20 }} />
        <h4 style={{ fontWeight: 400, marginLeft: 10, paddingTop: 10 }}>
          {fromDate.format("dddd, LL")}
        </h4>
        <ScheduleIcon style={{ marginTop: 20, marginLeft: 10 }} />
        <h4 style={{ fontWeight: 400, marginLeft: 10, paddingTop: 10 }}>
          {fromDate.format("HH:mm")}
        </h4>
        {props.isEdit && (
          <Button
            justIcon
            round
            simple
            onClick={props.onEdit}
            size="lg"
            color="primary"
            className="edit"
          >
            <EditIcon />
          </Button>
        )}
      </Box>
      {props.original_start_date && (
        <div style={{ marginLeft: 5, marginTop: -5, fontSize: 12 }}>
          ( Original date:{" "}
          {moment(props.original_start_date).format("dddd, LL HH:mm") + " "})
        </div>
      )}
      {props.toDate && (
        <Box display="flex">
          <EventIcon style={{ marginTop: 10 }} />
          <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
            {toDate.format("dddd, LL")}
          </h4>
          <ScheduleIcon style={{ marginTop: 10, marginLeft: 10 }} />
          <h4 style={{ fontWeight: 400, marginLeft: 10 }}>
            {toDate.format("HH:mm")}
          </h4>
          {props.isEdit && (
            <Button
              justIcon
              round
              simple
              onClick={props.onEditEndDate}
              size="lg"
              color="primary"
              style={{ marginTop: -5 }}
              className="edit"
            >
              <EditIcon />
            </Button>
          )}
        </Box>
      )}
    </Grid>
  );
};

export default RequestDateItem;

import React, { useState, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import { carsActions } from "../../redux/cars/actions";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import NewCarDialog from "./NewCarDialog";
import { isAdmin } from "../../constants";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  input: {
    paddingTop: 0,
  },
};

const useStyles = makeStyles({
  ...styles,
  ...alertStyles,
});

function filterCaseInsensitive(filter, row) {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
    : true;
}

export const CarsManagement = () => {
  const dispatch = useDispatch();
  const [isNewTruckVisible, setNewTruckVisible] = useState(false);
  const [alert, setAlert] = useState(null);
  const [plateNumberState, setPlateNumberState] = useState("");
  const [car, setCar] = useState({});
  const { cars, saving, savingSuccess, deleteSuccess } = useSelector(
    (state) => state.cars
  );
  const [isEdit, setEdit] = useState(false);
  const [data, setData] = useState([]);

  const onDataChanged = (key, value) => {
    setCar({
      ...car,
      [key]: value,
    });

    if (key === "plate_number") {
      if (value.length >= 5) {
        setPlateNumberState("success");
      } else {
        setPlateNumberState("error");
      }
    }
  };

  const onEdit = (car) => {
    setEdit(true);
    setCar(car);
    setPlateNumberState("");
    setNewTruckVisible(true);
  };

  useEffect(() => {
    if (savingSuccess) {
      setNewTruckVisible(false);
      const action = isEdit ? "updated" : "added";
      setAlert(
        <SweetAlert
          success
          title={`Car ${action}`}
          onConfirm={onDismissSave}
          onCancel={onDismissSave}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Car has been {isEdit ? "updated" : "added"}.
        </SweetAlert>
      );
    }
  }, [savingSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      setAlert(
        <SweetAlert
          success
          title="Deleted!"
          onConfirm={onDismissDelete}
          onCancel={onDismissDelete}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          The Car has been deleted.
        </SweetAlert>
      );
    }
  }, [deleteSuccess]);

  const onDismissDelete = () => {
    setAlert(null);
    dispatch(carsActions.resetSuccessDelete());
  };
  const onDismissSave = () => {
    setAlert(null);
    dispatch(carsActions.resetSuccessSave());
  };

  const warningWithConfirmMessage = (obj) => {
    setAlert(
      <SweetAlert
        warning
        title="Are you sure?"
        onConfirm={() => onDelete(obj)}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover the car.
      </SweetAlert>
    );
  };

  const onDelete = (obj) => {
    dispatch(carsActions.deleteCar(obj.id));
  };

  useEffect(() => {
    setData(
      cars.map((prop, key) => {
        return {
          id: key,
          plate_number: prop.plate_number,
          subcontractor: prop.subcontractor_id
            ? prop.subcontractor.company_name
            : "Protectrans",
          color: prop.color || "N/A",
          brand: prop.brand || "N/A",
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  let car = cars.find((o) => o.id === prop.id);
                  onEdit(car);
                }}
                size="lg"
                color="primary"
                className="edit"
              >
                <EditIcon />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  const obj = cars.find((o) => o.id === prop.id);
                  warningWithConfirmMessage(obj);
                }}
                size="lg"
                color="danger"
                className="remove"
              >
                <DeleteForeverIcon />
              </Button>{" "}
            </div>
          ),
        };
      })
    );
  }, [cars]);

  const onSubmit = () => {
    if (plateNumberState !== "error") {
      dispatch(carsActions.saveCar(car));
    } else {
      setPlateNumberState("error");
    }
  };

  const onOpenDialog = () => {
    setCar({
      plate_number: "",
      color: "",
      brand: "",
    });
    setPlateNumberState("");
    setNewTruckVisible(true);
    setEdit(false);
  };

  const classes = useStyles();

  const columns = isAdmin
    ? [
        {
          Header: "Plate Number",
          accessor: "plate_number",
        },
        {
          Header: "Brand",
          accessor: "brand",
        },
        {
          Header: "Color",
          accessor: "color",
        },
        {
          Header: "Subcontractor",
          accessor: "subcontractor",
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false,
        },
      ]
    : [
        {
          Header: "Plate Number",
          accessor: "plate_number",
        },
        {
          Header: "Brand",
          accessor: "brand",
        },
        {
          Header: "Color",
          accessor: "color",
        },
        {
          Header: "Actions",
          accessor: "actions",
          sortable: false,
          filterable: false,
        },
      ];
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Button
          onClick={onOpenDialog}
          style={{ float: "right", marginBottom: 10 }}
          size="lg"
          color="primary"
          variant="contained"
        >
          Add New Car
        </Button>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <DirectionsCarIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Cars Management ({cars.length})
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              FilterComponent={({ filter, onChange }) => (
                <CustomInput
                  value={filter ? filter.value : ""}
                  onChange={(value) => onChange(value)}
                  id="success"
                  // labelText="Plate number"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.input,
                  }}
                  inputProps={{
                    value: filter ? filter.value : "",
                  }}
                />
              )}
              data={data}
              filterable
              defaultFilterMethod={filterCaseInsensitive}
              columns={columns}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      <NewCarDialog
        title={car.id ? "Edit Car" : "Add new Car"}
        onClose={() => setNewTruckVisible(false)}
        onSubmit={onSubmit}
        onDataChanged={onDataChanged}
        data={car}
        plateNumberState={plateNumberState}
        isModalVisible={isNewTruckVisible}
        saving={saving}
      />
      {alert}
    </GridContainer>
  );
};

export default CarsManagement;

import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

import {
  Typography,
  TextareaAutosize,
  CircularProgress,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isAdmin, isSubContractor, REQUEST_STATUS } from "../../../constants";
import { getUserOffice } from "redux/requests/selectors";
import { requestsActions } from "redux/requests/actions";

const RequestNote = ({ request, isOffice }) => {
  const dispatch = useDispatch();
  const isRoleOffice = useSelector(getUserOffice);
  const [securityNotes, setSecurityNotes] = useState(
    request.security_notes || ""
  );
  const { saving } = useSelector((state) => state.requests);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <GridItem style={{ marginTop: 20 }}>
          <Typography style={{ fontSize: 16, float: "left" }} variant="h6">
            Notes (Optional)
          </Typography>
        </GridItem>
        <TextareaAutosize
          style={{
            width: "100%",
            fontSize: 20,
            float: "left",
            padding: 10,
            marginLeft: 20,
            fontFamily: "Helvetica",
            fontWeight: 200,
            marginTop: 20,
          }}
          readOnly={
            request.status === REQUEST_STATUS.ARCHIVED ||
            request.status === REQUEST_STATUS.CHECKED
          }
          disabled={isOffice}
          value={securityNotes}
          rows={5}
          name="note"
          onChange={(event) => setSecurityNotes(event.target.value)}
          placeholder="add notes here..."
        />

        {saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginTop: 20 }}
          />
        ) : (
          <>
            {((isAdmin || isSubContractor) && !isRoleOffice) ||
            (isRoleOffice && request.status === REQUEST_STATUS.ACCEPTED) ? (
              <>
                <Button
                  disabled={
                    !securityNotes ||
                    (request.security_notes &&
                      request.security_notes == securityNotes)
                  }
                  onClick={() => {
                    dispatch(
                      requestsActions.editNote(securityNotes, request.id)
                    );
                    request.security_notes = securityNotes;
                  }}
                  style={{ marginTop: 20 }}
                  size="lg"
                  color="primary"
                >
                  SAVE
                </Button>
              </>
            ) : null}
          </>
        )}
      </GridItem>
    </GridContainer>
  );
};

export default RequestNote;

import React, { useState } from "react";
import moment from "moment";
// @material-ui/core components
import { Grid, Divider, Box } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import SweetAlert from "react-bootstrap-sweetalert";
import EditIcon from "@material-ui/icons/Edit";

import Table from "components/Table/Table";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Primary from "components/Typography/Primary";
import {
  REQUEST_TYPE,
  REQUEST_STATUS,
  primaryColor,
} from "../../../../src/constants";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import RequestPlaceItem from "./RequestPlaceItem";
import RequestDateItem from "./RequestDateItem";
import Checkbox from "@material-ui/core/Checkbox";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import EditTruckDetailsDialog from "../EditRequest/EditTruckDetailsDialog";
import EditDateRequestDialog from "../EditRequest/EditDateRequestDialog";
import { useSelector, useDispatch } from "react-redux";
import { requestsActions } from "redux/requests/actions";
import SecurityDetails from "./SecurityDetails";
import NewSecurityDetails from "./NewSecurityDetails";
import CustomInput from "components/CustomInput/CustomInput";
import ResumedSecurityDetails from "./ResumedSecurityDetails";
const style = {
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
  ...alertStyles,
};

const useStyles = makeStyles(style);

const generateIcon = (type) => {
  switch (type) {
    case REQUEST_TYPE.MONITORING:
      return "tv";
    case REQUEST_TYPE.STATIC:
      return "warehouse";
    case REQUEST_TYPE.ESCORT:
      return "car";
    case REQUEST_TYPE.INTERVENTION:
      return "tachometer-alt";
  }
};

const dateLeft = (date) => {
  const startDate = moment(date);
  const today = moment();
  let diffSeconds = startDate.diff(today, "seconds"); // 86400000
  if (diffSeconds < 0) return "";
  const days = Math.floor(diffSeconds / (3600 * 24));
  diffSeconds -= days * 3600 * 24;
  const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600);
  if (days > 0 && hours > 0) return `${days} day(s) and ${hours} hour(s) left`;
  if (days > 0 && hours == 0) return `${days} day(s) left`;
  if (days == 0 && hours > 0) return `${hours} hour(s) left`;
};

const RequestItem = (props) => {
  const classes = useStyles();
  const [alert, setAlert] = useState(null);

  const [notes, setNotes] = useState(props.request.notes);
  const [isOpen, setOpen] = useState(false);
  const [isOpenEndDate, setOpenEndDate] = useState(false);
  const [isOpenTakeOver, setOpenTakeOver] = useState(false);
  const [isOpenTransportDetails, setOpenTransportDetails] = useState(false);
  const [selectedTransport, setSelectedTransport] = useState(null);
  const status = props.request.status;
  const request = props.request;
  const startDate = request.start_date;
  const endDate = request.end_date;
  const requestIcon = generateIcon(props.request.type);
  const { editingSuccess, editingDateSuccess } = useSelector(
    (state) => state.requests
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (editingSuccess) {
      setSelectedTransport(null);
    }
  }, [editingSuccess]);

  React.useEffect(() => {
    if (editingDateSuccess) {
      setOpen(false);
      setOpenEndDate(false);
      setOpenTakeOver(false);
      dispatch(requestsActions.resetEditDateRequestSuccess());
    }
  }, [editingDateSuccess]);

  const onOpenEditTruckDetails = (transport) => {
    setOpenTransportDetails(true);
    if (transport) {
      let drivers = [transport.driver1];
      if (transport.driver2) drivers.push(transport.driver2);
      setSelectedTransport({
        ...transport,
        drivers,
      });
    } else {
      setSelectedTransport({
        drivers: [{ first_name: "" }],
      });
    }
  };

  const onOpenEditStartDate = () => {
    setOpen(true);
  };
  const onOpenEditEndDate = () => {
    setOpenEndDate(true);
  };
  const onOpenEditStartDateTakeOver = () => {
    setOpenTakeOver(true);
  };

  const onEditTransportRequest = (editTransport) => {
    if (editTransport.id) {
      dispatch(
        requestsActions.editTransportsRequest({
          ...editTransport,
          driver1: editTransport.drivers[0],
          driver2:
            editTransport.drivers.length > 1 ? editTransport.drivers[1] : null,
        })
      );
    } else {
      editTransport.request_id = props.request.id;
      dispatch(
        requestsActions.addTransportsRequest({
          ...editTransport,
          driver1: editTransport.drivers[0],
          driver2:
            editTransport.drivers.length > 1 ? editTransport.drivers[1] : null,
        })
      );
    }
  };
  const generateTransportsDetails = (transports) => {
    let rows = [];
    for (let i = 0; i < transports.length; i++) {
      const transport = transports[i];
      let columns = [];
      if (transport.truck && transport.truck.plate_number) {
        columns.push(
          <Grid>
            {transport.truck.plate_number}
            {transport.truck.brand ? <div>{transport.truck.brand}</div> : ""}

            {transport.truck.color ? <div>{transport.truck.color}</div> : ""}
          </Grid>
        );
      } else {
        columns.push(
          <Grid style={{ color: "red" }}>
            Truck{i + 1} : missing information
          </Grid>
        );
      }

      if (transport.trailer) {
        columns.push(
          <Grid>
            {transport.trailer.plate_number}
            {transport.trailer.brand ? (
              <div>{transport.trailer.brand}</div>
            ) : (
              ""
            )}
            <br />
            {transport.trailer.color ? (
              <div>{transport.trailer.color}</div>
            ) : (
              ""
            )}
          </Grid>
        );
      } else {
        columns.push(<Grid>N/A</Grid>);
      }

      const driver1 = transport.driver1;
      const driver2 = transport.driver2;
      columns.push(
        <Grid>
          {driver1 && driver1.first_name ? (
            <div>
              1. {driver1.first_name} {driver1.last_name}
              <br />
              <div style={{ marginLeft: 15 }}>
                {driver1.phone_number ? driver1.phone_number : ""}
              </div>
              <br />
            </div>
          ) : (
            <div style={{ color: "red" }}>Driver 1 : missing information</div>
          )}
          {driver2 && (
            <div>
              2. {driver2.first_name} {driver2.last_name}
              <br />
              {driver2.phone_number ? +" " + driver2.phone_number : ""}
            </div>
          )}
        </Grid>
      );
      if (
        request.status == REQUEST_STATUS.ACCEPTED ||
        request.status == REQUEST_STATUS.PENDING
      ) {
        columns.push(
          <Button
            justIcon
            round
            simple
            onClick={() => onOpenEditTruckDetails(transport)}
            size="lg"
            color="primary"
            className="edit"
          >
            <EditIcon />
          </Button>
        );
      }

      rows.push(columns);
    }

    return rows;
  };
  const onEditNotes = () => {
    dispatch(
      requestsActions.updateRequestNotes({ id: props.request.id, notes })
    );
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
        <Card>
          <div
            style={{
              position: "absolute",
              top: -30,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <Primary>
                <h1
                  style={{ fontWeight: 400, textAlign: "center", marginTop: 0 }}
                >
                  {props.request.type}
                </h1>
                {(status == REQUEST_STATUS.PENDING ||
                  status == REQUEST_STATUS.ACCEPTED) && (
                  <Typography style={{ marginTop: -20, fontSize: 14 }}>
                    {dateLeft(startDate)}
                  </Typography>
                )}
              </Primary>
            </div>
          </div>
          <CardHeader>
            <Grid style={{ position: "absolute", right: 20, top: 10 }}>
              <Typography
                style={{ float: "right" }}
                variant="h4"
                color="primary"
              >
                #{request.identifier}
              </Typography>
              {(status == REQUEST_STATUS.PENDING ||
                status == REQUEST_STATUS.ACCEPTED) && (
                <Grid>
                  <Button
                    onClick={() => props.onCancel(props.request)}
                    color="youtube"
                    variant="outlined"
                    style={{ marginTop: 20 }}
                  >
                    Cancel Request
                  </Button>
                </Grid>
              )}
              {(status == REQUEST_STATUS.ARCHIVED ||
                status == REQUEST_STATUS.FINISHED ||
                status == REQUEST_STATUS.ACCEPTED ||
                status == REQUEST_STATUS.CHECKED) && (
                <Grid>
                  <Button
                    onClick={() => props.onOpenSummary(props.request)}
                    color="primary"
                    variant="outlined"
                    style={{ marginTop: 20 }}
                  >
                    Request Summary
                  </Button>
                </Grid>
              )}
            </Grid>
            <GridContainer
              justify="space-between"
              alignItems="center"
              direction="row"
            >
              <GridItem>
                <RequestDateItem
                  isEdit={
                    status == REQUEST_STATUS.PENDING ||
                    status == REQUEST_STATUS.ACCEPTED
                  }
                  onEdit={onOpenEditStartDate}
                  onEditEndDate={onOpenEditEndDate}
                  fromDate={request.start_date}
                  toDate={request.end_date}
                  original_start_date={request.original_start_date}
                />
                {request.take_over_pup ? (
                  <>
                    <RequestPlaceItem
                      pup={request.pup}
                      dop={request.take_over_dop}
                    />
                    <label
                      style={{
                        color: primaryColor,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Take Over
                    </label>
                    <RequestDateItem
                      isEdit={
                        status == REQUEST_STATUS.PENDING ||
                        status == REQUEST_STATUS.ACCEPTED
                      }
                      onEdit={onOpenEditStartDateTakeOver}
                      original_start_date={
                        request.take_over_original_start_date
                      }
                      fromDate={request.take_over_start_date}
                    />

                    <RequestPlaceItem
                      pup={request.take_over_pup}
                      dop={request.dop}
                    />
                  </>
                ) : (
                  <RequestPlaceItem pup={request.pup} dop={request.dop} />
                )}
                {request.type == REQUEST_TYPE.MONITORING && (
                  <GridItem style={{ marginLeft: 5 }}>
                    <Grid>
                      <Box display="flex">
                        <p style={{ marginTop: 3, fontSize: 16 }}>
                          <label
                            style={{
                              fontWeight: 500,
                              marginTop: 3,
                              color: "#000",
                              marginRight: 10,
                            }}
                          >
                            Monitoring type :{" "}
                          </label>
                          {request.monitoring_type || request.Monitoring_type}
                        </p>
                      </Box>
                      <Box display="flex">
                        <p style={{ marginTop: 3, fontSize: 16 }}>
                          <label
                            style={{
                              fontWeight: 500,
                              marginTop: 3,
                              color: "#000",
                              marginRight: 10,
                            }}
                          >
                            GPS Url :{" "}
                          </label>
                          {request.gps_url}
                        </p>
                      </Box>
                      <Box display="flex">
                        <p style={{ marginTop: 3, fontSize: 16 }}>
                          <label
                            style={{
                              fontWeight: 500,
                              marginTop: 3,
                              color: "#000",
                              marginRight: 10,
                            }}
                          >
                            GPS Username :{" "}
                          </label>
                          {request.gps_username}
                        </p>
                      </Box>
                      <Box display="flex">
                        <p style={{ marginTop: 3, fontSize: 16 }}>
                          <label
                            style={{
                              fontWeight: 500,
                              marginTop: 3,
                              color: "#000",
                              marginRight: 10,
                            }}
                          >
                            GPS Password :{" "}
                          </label>
                          {request.gps_password}
                        </p>
                      </Box>
                    </Grid>
                  </GridItem>
                )}
              </GridItem>
              <GridItem>
                {/* {(status == REQUEST_STATUS.PENDING ||
                  status == REQUEST_STATUS.ACCEPTED) && (
                  <GridItem>
                    <Button
                      onClick={() => props.onEdit(props.request)}
                      color="primary"
                      variant="outlined"
                      style={{ marginLeft: 20 }}
                    >
                      Edit Request
                    </Button>
                    <Button
                      onClick={() => props.onCancel(props.request)}
                      color="youtube"
                      variant="outlined"
                    >
                      Cancel Request
                    </Button>
                  </GridItem>
                )} */}
                {/* <Checkbox
                  checked={true}
                  color="primary"
                  checkedIcon={
                    <i
                      className={
                        "fas fa-" + requestIcon + " " + classes.iconCheckboxIcon
                      }
                    />
                  }
                  classes={{
                    checked: classes.iconCheckboxChecked,
                    root: classes.iconCheckbox
                  }}
                  style={{ margin: 0, backgroundColor: "#fff" }}
                /> */}
              </GridItem>
            </GridContainer>
            {/* <GridContainer>
            
            </GridContainer> */}
            <GridContainer>
              {request.type == REQUEST_TYPE.INTERVENTION && (
                <GridItem style={{ marginLeft: 5 }}>
                  <Grid>
                    <Box display="flex">
                      <p style={{ marginTop: 3, fontSize: 16 }}>
                        <label
                          style={{
                            fontWeight: 500,
                            marginTop: 3,
                            color: "#000",
                            marginRight: 10,
                          }}
                        >
                          Number of security agents :{" "}
                        </label>
                        {request.escort_number}
                      </p>
                    </Box>
                  </Grid>
                </GridItem>
              )}
              {request.type == REQUEST_TYPE.ESCORT && (
                <GridItem style={{ marginLeft: 5 }}>
                  <Grid>
                    <Box display="flex">
                      <p style={{ marginTop: 3, fontSize: 16 }}>
                        <label
                          style={{
                            fontWeight: 500,
                            marginTop: 3,
                            color: "#000",
                            marginRight: 10,
                          }}
                        >
                          Number of security vehicles :{" "}
                        </label>
                        {request.escort_number}
                      </p>
                    </Box>
                  </Grid>
                  {request.status == REQUEST_STATUS.PENDING && (
                    <>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Trucks : </span>
                        {request.client_transport
                          .map((item, index) =>
                            item.truck
                              ? item.truck.plate_number +
                                " " +
                                `${
                                  item.truck.brand
                                    ? +" - " + item.truck.brand
                                    : ""
                                }` +
                                " " +
                                `${
                                  item.truck.color
                                    ? " - " + item.truck.color
                                    : ""
                                }`
                              : "Truck " + (index + 1) + ": missing information"
                          )
                          .join(" / ")}
                      </div>
                      <div>
                        <span style={{ fontWeight: "bold" }}>Drivers : </span>
                        {request.client_transport
                          .map(
                            (item, index) =>
                              `${
                                item.driver1
                                  ? item.driver1.first_name +
                                    " " +
                                    item.driver1.last_name +
                                    " " +
                                    `${
                                      item.driver1.phone_number
                                        ? item.driver1.phone_number
                                        : ""
                                    }`
                                  : "N/A"
                              }` +
                              " " +
                              `${
                                item.driver2
                                  ? " / " +
                                    item.driver2.first_name +
                                    " " +
                                    item.driver2.last_name +
                                    " " +
                                    `${
                                      item.driver2.phone_number
                                        ? item.driver2.phone_number
                                        : ""
                                    }`
                                  : ""
                              }`
                          )
                          .join(" / ")}
                      </div>
                    </>
                  )}

                  {request.security_notes && (
                    <Box display="flex">
                      <Typography
                        color="primary"
                        style={{ fontWeight: 400, textDecoration: "underline" }}
                      >
                        Security Notes :
                      </Typography>
                      <Typography
                        color="primary"
                        style={{ fontWeight: 400, marginLeft: 5 }}
                      >
                        {request.security_notes
                          ? request.security_notes
                          : "N/A"}
                      </Typography>
                    </Box>
                  )}
                </GridItem>
              )}
            </GridContainer>
          </CardHeader>
          <CardBody style={{ paddingTop: 0 }}>
            <ResumedSecurityDetails request={request} />
            <Accordion>
              <AccordionSummary
                style={{ marginTop: 10 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{ color: primaryColor, fontWeight: "bold" }}
                  className={classes.heading}
                >
                  {props.request.type === REQUEST_TYPE.STATIC ||
                  props.request.type === REQUEST_TYPE.MONITORING
                    ? `View ${props.request.type} details`
                    : "Add/Edit Escort details"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <GridContainer justify="space-between" direction="row">
                  <GridItem xs={12} sm={6} md={6}>
                    <h2>Your {props.request.type} Details</h2>
                    {request.type !== REQUEST_TYPE.STATIC && (
                      <>
                        {(props.request.status == REQUEST_STATUS.ACCEPTED ||
                          props.request.status == REQUEST_STATUS.PENDING) && (
                          <Button
                            onClick={() => onOpenEditTruckDetails()}
                            color="primary"
                          >
                            Add new Truck
                          </Button>
                        )}
                        <Table
                          tableHead={["Trucks", "Trailers", "Drivers"]}
                          tableData={generateTransportsDetails(
                            request.client_transport
                          )}
                        />
                      </>
                    )}
                    {request.type == REQUEST_TYPE.STATIC && (
                      <Grid>
                        <Box display="flex">
                          <h4 style={{ fontWeight: 400 }}>
                            Number of security guards :{" "}
                          </h4>
                          <h4 style={{ marginLeft: 10 }}>
                            {" "}
                            {request.number_agents}
                          </h4>
                        </Box>
                        <Box display="flex">
                          <h4 style={{ fontWeight: 400 }}>
                            Responsible name :{" "}
                          </h4>
                          <h4 style={{ marginLeft: 10 }}>
                            {" "}
                            {request.responsible_name}
                          </h4>
                        </Box>
                        <Box display="flex">
                          <h4 style={{ fontWeight: 400 }}>Phone number : </h4>
                          <h4 style={{ marginLeft: 10 }}>
                            {" "}
                            {request.phone_number}
                          </h4>
                        </Box>
                        <Box>
                          <h4 style={{ fontWeight: 400 }}>Intructions : </h4>
                          <h4> {request.instructions}</h4>
                        </Box>
                      </Grid>
                    )}

                    <Box style={{ marginTop: 20 }}>
                      <Typography
                        variant="h5"
                        color="primary"
                        style={{
                          fontWeight: 400,
                          textDecoration: "underline",
                        }}
                      >
                        Notes
                      </Typography>
                      <CustomInput
                        id="success"
                        multiline
                        style={{ marginTop: 20 }}
                        labelText="Notes"
                        value={notes}
                        onChange={(text) => setNotes(text)}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                      {/* <Typography color="primary" style={{ fontWeight: 400 }}>
                      {request.notes}
                    </Typography> */}
                      <Button
                        disabled={notes == props.request.notes}
                        onClick={onEditNotes}
                        color="primary"
                      >
                        Save
                      </Button>
                    </Box>
                  </GridItem>
                  {/* <GridItem>
                    <Divider
                      variant="inset"
                      orientation="vertical"
                      style={{ height: "100%", width: 1 }}
                    />
                  </GridItem>
                  {request.is_new == 1 ? (
                    <NewSecurityDetails request={request} />
                  ) : (
                    <SecurityDetails request={request} />
                  )} */}
                </GridContainer>
              </AccordionDetails>
            </Accordion>
          </CardBody>
        </Card>
      </GridItem>
      {isOpenTransportDetails && selectedTransport && (
        <EditTruckDetailsDialog
          onClose={() => {
            setSelectedTransport(null);
            setOpenTransportDetails(false);
          }}
          isModalVisible={!!selectedTransport}
          transport={selectedTransport}
          request={{ ...request }}
          onSubmit={onEditTransportRequest}
        />
      )}
      {isOpen && (
        <EditDateRequestDialog
          onClose={() => setOpen(false)}
          isModalVisible={isOpen}
          request={{ ...request }}
          start_date_key="start_date"
          original_start_date={request.original_start_date}
          start_date={request.start_date}
        />
      )}
      {isOpenEndDate && (
        <EditDateRequestDialog
          onClose={() => setOpenEndDate(false)}
          isModalVisible={isOpenEndDate}
          request={{ ...request }}
          start_date_key="end_date"
          original_start_date={request.end_date}
          start_date={request.end_date}
        />
      )}
      {isOpenTakeOver && (
        <EditDateRequestDialog
          onClose={() => setOpenTakeOver(false)}
          isModalVisible={isOpenTakeOver}
          request={{ ...request }}
          start_date_key="take_over_start_date"
          original_start_date={request.take_over_original_start_date}
          start_date={request.take_over_start_date}
        />
      )}
      {alert}
    </GridContainer>
  );
};

export default RequestItem;

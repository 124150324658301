import React from "react";
import moment from "moment";
import Table from "components/Table/Table";
import { Grid } from "@material-ui/core";

const StaticOutTable = ({ data, setValid }) => {
  const analyseData = (input) => {
    const rows = [];
    input = input.filter((x) => x.length > 0);
    let errorCount = 0;
    for (let i = 0; i < input.length; i++) {
      let columns = [];
      for (let j = 0; j < input[i].length; j++) {
        let valid = true;
        let value = input[i][j] || "";
        // pup

        if (j <= 6 && j > 0) {
          valid = input[i][j] || (j == 1 && i > 0 && !input[i][j]);
          value = valid ? value : "(Missing)";
        }
        if (!valid) {
          errorCount++;
          columns.push(<Grid style={{ color: !valid && "red" }}>{value}</Grid>);
        } else {
          if (j === 2) {
            valid = moment(value, "dddd D MMMM YYYY").isValid();
            value = valid
              ? moment(value).format("DD/MM/YYYY")
              : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 3) {
            valid = moment("2020-08-20 " + value).isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 4) {
            valid = !value ? true : moment(value, "dddd D MMMM YYYY").isValid();
            value = valid
              ? moment(value).format("DD/MM/YYYY")
              : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          } else if (j === 5) {
            valid = !value ? true : moment("2020-08-20 " + value).isValid();
            value = valid ? value : value + " (Invalid)";
            errorCount = valid ? errorCount : errorCount + 1;
          }
          columns.push(<Grid style={{ color: !valid && "red" }}>{value}</Grid>);
        }
      }
      rows.push(columns);
    }
    setValid(errorCount);
    return rows;
  };
  return <Table tableHead={data[0]} tableData={analyseData(data.slice(1))} />;
};

export default StaticOutTable;

import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { ENotificationsActionTypes } from "./actions";

const defaultState = {
  notifications: [],
  unread: 0,
  paginatedRecentNotificationsUrl: null,
  loading: false,
};

const persistConfig = {
  key: "notifications",
  storage,
};

const notificationsReducer = persistReducer(
  persistConfig,
  (state = defaultState, action) => {
    switch (action.type) {
      case ENotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          notifications: action.payload.notifications,
          paginatedRecentNotificationsUrl:
            action.payload.paginatedRecentNotificationsUrl,
        };
      case ENotificationsActionTypes.GET_NOTIFICATIONS:
        return {
          ...state,
          loading: true,
        };
      case ENotificationsActionTypes.GET_NOTIFICATIONS_FAILED:
        return {
          ...state,
          loading: false,
        };
      case ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          unread: action.payload,
        };
      case ENotificationsActionTypes.CLEAR_UNREAD_COUNT_SUCCESS:
        return {
          ...state,
          unread: 0,
        };
      default:
        return state;
    }
  }
);

export default notificationsReducer;

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  TextField,
  Tabs,
  Tab,
  Box,
  ListItem,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { chatActions } from "redux/chat/actions";
import { requestsActions } from "redux/requests/actions";
import { getRequestStatus } from "redux/utils";
import ChannelImages from "./ChannelImages";
import ChannelMembers from "./ChannelMembers";
import RequestDetails from "./RequestDetails";
import { isAdmin } from "../../../constants";

const ChannelDetails = ({ onClose }) => {
  const dispatch = useDispatch();
  const { currentChannel } = useSelector((state) => state.chat);

  const [index, setIndex] = useState(0);
  const [channelName, setChannelName] = useState(
    currentChannel ? currentChannel.channels.name : ""
  );

  const [routes, setRoutes] = React.useState([
    {
      key: "first",
      title: `Shifts`,
    },
    { key: "second", title: "Images" },
    { key: "third", title: "Members" },
  ]);

  useEffect(() => {
    if (currentChannel) {
      dispatch(
        requestsActions.getRequestDetails(
          currentChannel.channels.requests.map((x) => x.request_id)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (currentChannel && currentChannel.members) {
      const members = [...new Set(currentChannel.members.map((x) => x.user))];
      setRoutes([
        {
          key: "first",
          title: `Shift(s)`,
        },
        {
          key: "second",
          title: `Images/Files`,
        },
        { key: "third", title: `Members (${members ? members.length : 0})` },
      ]);
    }
  }, [currentChannel]);

  const onChangeChannelDetails = () => {
    if (currentChannel) {
      dispatch(
        chatActions.setChannelDetails({
          channelName,
          id: currentChannel.channels.id,
          request_status: getRequestStatus(currentChannel.channels.requests),
        })
      );
    }
  };

  const onChangeIndex = (event, index) => {
    if (currentChannel) {
      setIndex(index);
      if (index === 1) {
        dispatch(
          requestsActions.getChannelImages({
            ids: currentChannel.channels.requests.map((x) => x.request_id),
            channelId:
              currentChannel.channels.requests.length > 1
                ? currentChannel.channel_id
                : null,
          })
        );
      }
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 999999999999999,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            color="inherit"
            aria-label="menu"
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {currentChannel.channels.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <ListItem>
        <TextField
          fullWidth
          disabled={!isAdmin}
          multiline={false}
          value={channelName}
          onChange={(e) => setChannelName(e.target.value)}
          id="filled-basic"
          label="channel name"
        />
        {isAdmin && (
          <Button
            onClick={onChangeChannelDetails}
            disabled={channelName == currentChannel.channels.name}
            style={{ height: 54, marginLeft: 10 }}
            size="large"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        )}
      </ListItem>
      <Tabs
        value={index}
        onChange={onChangeIndex}
        aria-label="simple tabs example"
      >
        {routes.map((route) => (
          <Tab key={route.key} label={route.title} {...a11yProps(0)} />
        ))}
      </Tabs>
      <TabPanel value={index} index={0}>
        <RequestDetails />
      </TabPanel>
      <TabPanel value={index} index={1}>
        <ChannelImages />
      </TabPanel>
      <TabPanel value={index} index={2}>
        <ChannelMembers />
      </TabPanel>
    </div>
  );
};

export default ChannelDetails;

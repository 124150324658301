import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import clientRoutes from "clientRoutes.js";
import adminRoutes from "adminRoutes.js";
import subCRoutes from "subCRoutes.js";
import officeRoutes from "officeRoutes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { ProtectedRoute } from "RouteMiddleware";
import BranchManagement from "views/Admin/BranchManagement";
import AccountsManagement from "views/Admin/AccountsManagement";
import SubcAccountsManagement from "views/Admin/SubcAccountsManagement";
import AdminProfile from "views/Admin/Profile";
import ClientProfile from "views/Client/Profile";
import Snackbar from "components/Snackbar/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import { errorsActions } from "../redux/errors/actions";
import { isClient, isAdmin, isSubContractor } from "../constants";
var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const dispatch = useDispatch();
  const role = process.env.REACT_APP_ROLE;
  const { errorMessage } = useSelector((state) => state.errors);
  const { user } = useSelector((state) => state.user);
  const isOffice = user.roles && user.roles[0] === "ROLE_OFFICE";
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("white");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo.png"));
  const [logo_white, setLogoWhite] = React.useState(
    require("assets/img/logo_white.png")
  );
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (
        (prop.layout === "/client" && isClient) ||
        (prop.layout === "/admin" && isAdmin) ||
        (prop.layout === "/subc" && isSubContractor)
      ) {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  let routes = [];
  if (isClient) routes = clientRoutes;
  else if (isOffice) routes = officeRoutes;
  else if (isAdmin) routes = adminRoutes;
  else if (isSubContractor) routes = subCRoutes;

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        //logoText={"Creative Tim"}
        logo_white={logo_white}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute([
            ...routes,
            {
              path: "/branches",
              name: "Branches",
              layout: "/admin",
            },
            {
              path: "/accounts",
              name: "Accounts",
              layout: "/admin",
            },
            {
              path: "/subc-accounts",
              name: "Subcontractor Accounts",
              layout: "/admin",
            },
            {
              path: "/profile",
              name: "Profile",
              layout: "/admin",
            },
            {
              path: "/profile",
              name: "Profile",
              layout: "/client",
            },
          ])}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              {isClient ? (
                <ProtectedRoute
                  path="/client/profile"
                  component={ClientProfile}
                />
              ) : isAdmin ? (
                <>
                  <ProtectedRoute
                    path="/admin/branches/:id"
                    component={BranchManagement}
                  />
                  <ProtectedRoute
                    path="/admin/accounts/:client_id/:branch_id"
                    component={AccountsManagement}
                  />
                  <ProtectedRoute
                    path="/admin/subc-accounts/:id"
                    component={SubcAccountsManagement}
                  />
                  <ProtectedRoute
                    path="/admin/profile"
                    component={AdminProfile}
                  />
                </>
              ) : (
                <>
                  <ProtectedRoute
                    path="/subc/profile"
                    component={AdminProfile}
                  />
                </>
              )}
              <Redirect from={`/${role}`} to={`/${role}/Dashboard`} />
            </Switch>
          </div>
        </div>
        <Footer fluid />
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
      {!!errorMessage && (
        <Snackbar
          place="tr"
          color="danger"
          message={errorMessage}
          open={!!errorMessage}
          closeNotification={() => dispatch(errorsActions.resetErrorMessage())}
          close
        />
      )}
    </div>
  );
}

import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import TransportDetails from "../NewRequest/Steps/Step2/TransportDetails";
import {
  Slide,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const EditTruckDetailsDialog = ({
  transport,
  request,
  isModalVisible,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles(styles);
  const [driverError, setDriverError] = useState();
  const [alert, setAlert] = React.useState(null);
  const [editTransport, setEditTransport] = React.useState(transport);
  const [transports, setTransports] = React.useState(request.client_transport);
  const [isFormValid, setValid] = useState(true);
  const requests = useSelector((state) => state);
  const { saving, savingSuccess } = useSelector((state) => state.requests);

  const onSelectTransport = (key, value, driverIndex) => {
    let updatedTransport;
    if (driverIndex != undefined) {
      let drivers = [...editTransport.drivers];
      if (value) {
        drivers[driverIndex] = {
          ...value,
          driverFirstNameState: "success",
          driverLastNameState: "success",
          driverPhoneNumberState: "success",
        };
      } else {
        drivers[driverIndex] = {
          first_name: "",
          last_name: "",
          phone_number: "",
          driverFirstNameState: "",
          driverLastNameState: "",
          driverPhoneNumberState: "",
        };
      }

      updatedTransport = {
        ...editTransport,
        [key]: [...drivers],
      };
    } else {
      if (value) {
        updatedTransport = {
          ...editTransport,
          [key]: {
            ...value,
            plateNumberState: "success",
          },
        };
      } else {
        updatedTransport = {
          ...editTransport,
          [key]: {
            plate_number: "",
            color: "",
            brand: "",
            plateNumberState: "",
          },
        };
      }
    }

    setEditTransport(updatedTransport);
  };

  const onAddDriver = () => {
    const drivers = editTransport.drivers;
    drivers.push({ key: Math.random() });
    setEditTransport({
      ...editTransport,
      drivers,
    });
  };
  const onRemoveDriver = (driver) => {
    setDriverError(null);
    const drivers = editTransport.drivers;
    drivers.splice(driver, 1);
    setEditTransport({
      ...editTransport,
      drivers,
    });
  };
  const onTransportChanged = (key, nestedKey, value, driverIndex) => {
    let isValid = true;
    let updatedTransport;
    if (driverIndex !== undefined) {
      let driverFirstNameState = editTransport[key][driverIndex]
        ? editTransport[key][driverIndex].driverFirstNameState
        : "";
      let driverLastNameState = editTransport[key][driverIndex]
        ? editTransport[key][driverIndex].driverLastNameState
        : "";
      let driverPhoneNumberState = editTransport[key][driverIndex]
        ? editTransport[key][driverIndex].driverPhoneNumberState
        : "";

      if (nestedKey === "first_name") {
        if (!value) {
          driverFirstNameState = "error";
          isValid = false;
        } else driverFirstNameState = "success";
      } else if (nestedKey === "last_name") {
        if (!value) {
          driverLastNameState = "error";
          isValid = false;
        } else driverLastNameState = "success";
      } else if (nestedKey === "phone_number") {
        if (!value) driverPhoneNumberState = "";
        else {
          if (value.length < 9) {
            driverPhoneNumberState = "error";
            isValid = false;
          } else driverPhoneNumberState = "success";
        }
      }

      let drivers = editTransport[key];
      drivers[driverIndex] = {
        ...drivers[driverIndex],
        [nestedKey]: value,
        driverFirstNameState,
        driverLastNameState,
        driverPhoneNumberState,
      };

      updatedTransport = {
        ...editTransport,
        [key]: [...drivers],
      };
      setEditTransport(updatedTransport);
    } else {
      let plateNumberState = editTransport[key]
        ? editTransport[key].plateNumberState
        : "";
      if (nestedKey === "plate_number") {
        if (value.length < 5) {
          plateNumberState = "error";
          isValid = false;
        } else plateNumberState = "success";
      }
      if (nestedKey === "plate_number" && plateNumberState === "success") {
        const existTransport = requests[key + "s"][key + "s"].find(
          (x) => x.plate_number.toUpperCase() === value.toUpperCase()
        );
        if (existTransport) {
          if (
            transports.filter(
              (item) => item[key] && item[key].id === existTransport.id
            ).length > 0
          ) {
            // transport have been already chosen
            updatedTransport = {
              ...editTransport,
              [key]: {
                ...editTransport[key],
                [nestedKey]: "",
                id: undefined,
                plateNumberState: "error",
              },
            };
            isValid = false;
            setEditTransport(updatedTransport);
          } else {
            updatedTransport = {
              ...editTransport,
              [key]: {
                ...existTransport,
                plateNumberState,
              },
            };
          }
          if (plateNumberState === "error") {
            setValid(false);
          }
          setEditTransport(updatedTransport);

          return;
        }
      }
      updatedTransport = {
        ...editTransport,
        [key]: {
          ...editTransport[key],
          [nestedKey]: value,
          id: undefined,
          plateNumberState,
        },
      };
      if (plateNumberState === "error") {
        setValid(false);
      } else setValid(isValid);
      setEditTransport(updatedTransport);
    }
  };

  const onSave = () => {
    setDriverError(null);
    let validTruck = editTransport.truck
      ? editTransport.truck.plateNumberState
      : "";
    let validTrailer = editTransport.trailer
      ? editTransport.trailer.plateNumberState
      : "";
    const drivers = editTransport.drivers;
    if (
      drivers.length === 2 &&
      (!drivers[1].first_name || !drivers[1].last_name)
    ) {
      setDriverError(
        "Second Driver information is incomplete! if you do not have his information then please remove it!"
      );
      return;
    }
    if (validTruck !== "error" && validTrailer !== "error")
      onSubmit(editTransport);
    // for (let i = 0; i < drivers.length; i++) {
    //   if (
    //     drivers[i].driverFirstNameState === "error" ||
    //     drivers[i].driverLastNameState === "error" ||
    //     drivers[i].driverPhoneNumberState === "error"
    //   )
    //     return;
    // }
  };
  return (
    <Dialog
      disableEnforceFocus={true}
      fullScreen
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={isModalVisible}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h3 className={classes.modalTitle}>Edit Truck & Drivers details</h3>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classes.modalBody}
      >
        <GridContainer>
          <TransportDetails
            key={editTransport.id}
            isEdit
            onSelect={(key, value, driverIndex) =>
              onSelectTransport(key, value, driverIndex)
            }
            driverError={driverError}
            transport={editTransport}
            transports={transports}
            onChange={(key, nestedKey, value, driverIndex) =>
              onTransportChanged(key, nestedKey, value, driverIndex)
            }
            onRemoveDriver={(driver) => onRemoveDriver(driver)}
            onAddDriver={() => onAddDriver()}
            allowRemove={false}
            requestType={request.type}
          />
        </GridContainer>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button onClick={onClose} variant="contained" color="primary" simple>
          Close
        </Button>
        {saving ? (
          <CircularProgress
            size={30}
            color="primary"
            style={{ marginRight: 30 }}
          />
        ) : (
          <Button onClick={onSave} color="primary" variant="contained">
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditTruckDetailsDialog;

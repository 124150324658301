import { combineReducers } from "redux";
import requests from "./requests/reducer";
import chat from "./chat/reducer";
import drivers from "./drivers/reducer";
import trucks from "./trucks/reducer";
import trailers from "./trailers/reducer";
import user from "./user/reducer";
import cars from "./cars/reducer";
import agents from "./agents/reducer";
import clients from "./clients/reducer";
import branches from "./branches/reducer";
import accounts from "./accounts/reducer";
import subcaccounts from "./subcaccounts/reducer";
import errors from "./errors/reducer";
import subcontractors from "./subcontractors/reducer";
import notifications from "./notifications/reducer";
import { connectRouter } from "connected-react-router";
import { isClient, isAdmin } from "../constants";

const clientReducer = {
  drivers,
  trucks,
  trailers,
  chat,
};
const adminReducer = {
  cars,
  agents,
  clients,
  branches,
  accounts,
  subcaccounts,
  subcontractors,
  chat,
  notifications,
};
const subcReducer = {
  cars,
  agents,
  chat,
};

const reducers = isClient
  ? clientReducer
  : isAdmin
  ? adminReducer
  : subcReducer;

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    requests,
    errors,
    ...reducers,
  });

export const createRootReducer = (history, state, action) => {
  // if(action && action.type === "@@router/LOCATION_CHANGE"){
  //   alert('change')
  // }
  return appReducer(history, state, action);
};

export default createRootReducer;

import { primaryColor, primaryColorRGBA } from "../../../constants";
import React from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  ListItem,
  Radio,
  Collapse,
} from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { getTrackingRequests } from "redux/requests/selectors";
import { requestsActions } from "redux/requests/actions";
import TeamHistory from "./TeamHistory";

const ActiveShifts = ({
  selectedShift,
  onSelectShift,
  selectedTeam,
  onSelectTeam,
  checkedRouteAgents,
  onChangeRouteAgents,
  teamHistory,
  teamHistoryChecked,
  onChangeTeamHistoryChecked,
  selectedRequest,
  archived,
  onSelectAllTeamHistoryChecked,
}) => {
  const dispatch = useDispatch();

  const requests = useSelector(getTrackingRequests);

  return (
    <>
      {requests.map((item) => (
        <div key={item.id}>
          {item.request_shift.map((shift) => (
            <Card key={shift.id}>
              <div
                style={{
                  padding: 10,
                  backgroundColor:
                    selectedShift.id == shift.id ? primaryColorRGBA : "white",
                }}
                onClick={() => onSelectShift(shift, item)}
                className="request_card"
              >
                <div style={{ fontWeight: "bold", color: primaryColor }}>
                  #{item.identifier}
                </div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#333",
                    }}
                  >
                    Start date:{" "}
                  </label>
                  {moment(shift.start_date).format("DD/MM/YYYY HH:mm")}
                </div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                  <label style={{ fontWeight: "bold", color: "#333" }}>
                    PUP:{" "}
                  </label>{" "}
                  {shift.pup}
                </div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>
                  <label style={{ fontWeight: "bold", color: "#333" }}>
                    DOP:{" "}
                  </label>{" "}
                  {shift.dop}
                </div>
                <Divider />
              </div>
              {shift.request_team.map((team) => {
                return (
                  <>
                    <ListItem
                      // disabled={shift.id != selectedShift.id}
                      onClick={() => onSelectTeam(team, item)}
                      divider
                      button
                      style={{
                        backgroundColor:
                          team.sos == 1
                            ? "#FF000050"
                            : team.sos == 2
                            ? "#00FF0050"
                            : "white",
                      }}
                      key={team.id}
                    >
                      <div style={{ position: "relative", width: "100%" }}>
                        <div style={{ position: "absolute", right: 0, top: 0 }}>
                          <Radio
                            checked={
                              selectedTeam && team && selectedTeam.id == team.id
                            }
                            color="primary"
                          />
                        </div>
                        <Box
                          display="flex"
                          style={{
                            color: primaryColor,
                            marginRight: 5,
                            marginBottom: 5,
                            flexDirection: "row",
                          }}
                        >
                          <DirectionsCarIcon />{" "}
                          <span style={{ marginTop: 5 }}>
                            {team.security_car.plate_number}
                          </span>
                        </Box>
                        <Box
                          display="flex"
                          style={{
                            color: primaryColor,
                            marginRight: 5,
                            marginBottom: 5,
                            flexDirection: "row",
                          }}
                        >
                          <SupervisorAccountIcon />{" "}
                          <span style={{ marginTop: 5 }}>
                            {team.agents
                              .map(
                                (x) =>
                                  x.agent.first_name + " " + x.agent.last_name
                              )
                              .join(" / ")}{" "}
                          </span>
                        </Box>

                        <div
                          style={{
                            color: primaryColor,
                            marginTop: 5,
                            fontWeight: "bold",
                          }}
                        >
                          {team.history_status}
                        </div>
                        {team.sos != 0 && (
                          <div
                            style={{
                              color: team.sos == 1 ? "red" : "green",
                              fontWeight: "bold",
                              fontSize: 18,
                              marginTop: 5,
                            }}
                          >
                            {team.sos == 1 ? "SOS" : "SAFE"}
                          </div>
                        )}
                      </div>
                    </ListItem>
                    <Collapse
                      in={!!selectedTeam && selectedTeam.id == team.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      {selectedTeam && (
                        <TeamHistory
                          archived={archived}
                          selectedRequest={selectedRequest}
                          onChangeRouteAgents={onChangeRouteAgents}
                          checkedRouteAgents={checkedRouteAgents}
                          team={selectedTeam}
                          teamHistory={teamHistory}
                          teamHistoryChecked={teamHistoryChecked}
                          onChangeTeamHistoryChecked={
                            onChangeTeamHistoryChecked
                          }
                          onSelectAllTeamHistoryChecked={
                            onSelectAllTeamHistoryChecked
                          }
                        />
                      )}
                    </Collapse>
                  </>
                );
              })}
              {!archived && (
                <Button
                  onClick={() =>
                    dispatch(requestsActions.searchRequest(item.identifier))
                  }
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  {"History"}
                </Button>
              )}
            </Card>
          ))}
        </div>
      ))}
    </>
  );
};

export default ActiveShifts;

export const ENotificationsActionTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILED: "GET_NOTIFICATIONS_FAILED",
  GET_UNREAD_NOTIFICATIONS: "GET_UNREAD_NOTIFICATIONS",
  GET_UNREAD_NOTIFICATIONS_SUCCESS: "GET_UNREAD_NOTIFICATIONS_SUCCESS",
  GET__UNREAD_NOTIFICATIONS_FAILED: "GET__UNREAD_NOTIFICATIONS_FAILED",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  CLEAR_UNREAD_COUNT: "CLEAR_UNREAD_COUNT",
  CLEAR_UNREAD_COUNT_SUCCESS: "CLEAR_UNREAD_COUNT_SUCCESS",
  CLEAR_UNREAD_COUNT_FAILED: "CLEAR_UNREAD_COUNT_FAILED",
};

export const notificationsActions = {
  fetchNotifications: (payload) => ({
    type: ENotificationsActionTypes.GET_NOTIFICATIONS,
    payload,
  }),
  fetchUnreadNotificationsCount: () => ({
    type: ENotificationsActionTypes.GET_UNREAD_NOTIFICATIONS,
  }),
  updateNotifications: (payload) => ({
    type: ENotificationsActionTypes.UPDATE_NOTIFICATIONS,
    payload,
  }),
  clearUnreadCount: () => ({
    type: ENotificationsActionTypes.CLEAR_UNREAD_COUNT,
  }),
};
